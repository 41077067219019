import SVGInline from 'react-svg-inline';

const WKMLogo = () => (
  <SVGInline
    className="wkm-logo"
    classSuffix=""
    accessibilityLabel="WKM Global logo"
    fill="#fff"
    svg={`
      <svg viewBox="0 0 922 406">
        <path d="M723.25,134.17H656V303H598.42V86.1h5.51q110.5,0,221,.07c16.41,0,32.54,2.34,47.78,9,20,8.71,30.2,24.91,34,45.57a128.35,128.35,0,0,1,2.18,22.83c.2,45,.1,90,.09,135,0,1.31-.12,2.62-.21,4.34H851.1c0-1.81,0-3.73,0-5.64,0-43.33,0-86.66-.24-130,0-5.75-1.34-11.55-2.6-17.21-1.68-7.53-6.8-12-14.14-14a34.7,34.7,0,0,0-8.31-1.61c-14.45-.16-28.89-.08-43.86-.08V302.93H723.25Z"/>
        <path d="M135.49,86.38h58.69V255.57H261V86.5H319V303.69h-5.44c-72.65,0-145.31.22-218-.21a181.51,181.51,0,0,1-37.53-4.62c-27.88-6.12-43.42-24.44-47.84-52.28a153.54,153.54,0,0,1-1.84-23.36c-.17-43.49-.08-87-.08-130.48,0-2,0-3.95,0-6.28H66c.09,2,.27,4.06.27,6.14,0,41.33,0,82.65.08,124A104.73,104.73,0,0,0,67.83,234c2.42,14,8,20,22,20.84,14.91.93,29.92.21,45.69.21Z"/>
        <path d="M417.07,303h-57.4V7.39h57.07V188.84l1.09.42q11.36-12.77,22.71-25.53,32.78-37,65.49-74.15A9.13,9.13,0,0,1,513.65,86c21,.17,42,.09,63,.09h5.49L481.37,192.43,581.66,302.89c-2.64.18-4.2.39-5.77.39-22.16,0-44.32-.06-66.48.09a9.25,9.25,0,0,1-8-3.85q-40.31-48.7-80.85-97.2c-.85-1-1.77-2-3.51-3.91Z"/>
        <path d="M802.68,370.63c2.26,4,5.44,7.41,6.1,11.25,1.82,10.64-2.88,16.78-13.8,17.5-11.4.74-22.89.17-34.82.17V344.26c12.63,0,25.22-.34,37.79.16,5.34.21,9.29,3.94,10.56,9.28s.94,10.64-3.41,14.83C804,369.54,802.88,370.46,802.68,370.63Zm-31.55-16.94c0,3.73-.2,7,.12,10.29.1,1,1.58,2.76,2.45,2.77,6,.09,12,0,17.92-.44,3.54-.27,5.06-2.9,5-6.33s-1.65-6-5.17-6.2C784.88,353.48,778.27,353.69,771.13,353.69ZM782,390.11V390c2.82,0,5.65.17,8.45,0,4.05-.3,6.43-2.54,6.4-6.75s-2.29-6.48-6.45-6.62-8.29-.07-12.43-.07c-6.84,0-6.84,0-6.85,6.59,0,7,0,7,6.9,7Z"/>
        <path d="M753,371.35c0,2,.13,4,0,6-1,13.53-7.85,21.11-21.22,23a46.48,46.48,0,0,1-12.44.08c-13.21-1.75-19.84-8.52-21.21-21.81a67.85,67.85,0,0,1,.19-15.88,26.73,26.73,0,0,1,4-10.59c5.57-8.62,19.85-12.07,33.32-8.58,10.1,2.62,16,9.68,17.22,20.81.26,2.31.28,4.65.41,7Zm-43.49-.12c.71,3.7,1.07,7.55,2.23,11.14,2,6.28,6.31,8.92,13.36,9,7.29.07,11.83-2.68,14-8.92a32.81,32.81,0,0,0,.18-21.08c-2-6.28-6.4-9.31-13.11-9.46-7.51-.17-12.26,2.57-14.47,9C710.55,364.11,710.21,367.63,709.48,371.23Z"/>
        <path d="M623.92,376.45V366.8c8.34,0,16.45-.09,24.55.12a3.76,3.76,0,0,1,2.63,2.57c.19,9.8.12,19.6.12,30.24-5.11,0-9.86,0-14.61,0-5.49,0-11,.27-16.46-.3-10.77-1.12-18.15-7.53-19.57-18.15a66.79,66.79,0,0,1,.66-20.76c1.86-10,8.74-15.72,18.78-16.39a229.58,229.58,0,0,1,27.94-.25c6.16.34,2.28,5.41,3.31,9.06-8.31,0-16.09,0-23.86,0-9.15,0-13.42,3.84-14.53,12.91-2.48,20.3,4.39,26.63,26.09,23.58v-13Z"/>
        <path d="M869.06,399.47c-1.4.13-2.05.22-2.71.24-9.72.24-9.88.3-13.2-8.9-1.23-3.41-3-4.63-6.54-4.45-5.31.28-10.65.21-16,0-2.92-.1-4.84.69-5.44,3.72a2.92,2.92,0,0,1-.18.47c-3.84,9.94-3.84,9.94-15.13,8.75.55-1.62,1-3.14,1.57-4.58,6.51-15.47,13.26-30.85,19.45-46.44,1.69-4.26,4.2-4.83,8-4.66,3.61.17,6.76-.43,8.66,4.37,6.29,15.91,13.25,31.56,19.94,47.32C868,396.51,868.41,397.74,869.06,399.47Zm-21.57-23-8.71-22.09-8.36,22.09Z"/>
        <path d="M696.45,399.07c-7.92,0-15.59.54-23.14-.15-7.94-.73-13.83-6.45-14.2-14.4-.62-13.26-.17-26.57-.17-40.35h10.93c0,8.56,0,17,0,25.46,0,3,0,6,0,9C670,387,672.63,390,681.2,390.46c5,.28,9.94.05,15.25.05Z"/>
        <path d="M907,390.79v9.31c-8.53-.46-16.88-.18-25-1.55a14.5,14.5,0,0,1-12.38-13.94c-.67-13.39-.19-26.83-.19-40.58H880.4v22c0,2.83,0,5.66,0,8.48,0,13.83,2.24,16,16.3,16.23C900,390.82,903.27,390.79,907,390.79Z"/>
      </svg>
    `}
  />
);

export default WKMLogo;
