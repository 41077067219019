import SVGInline from 'react-svg-inline';

const RSCLogo = () => (
  <SVGInline
    className="rsc-logo"
    classSuffix=""
    accessibilityLabel="Red Sofa Cafe logo"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 448.66666 103.66666" style="enable-background:new 0 0 448.66666 103.66666;" xml:space="preserve">
        <style type="text/css">
          .rsc0{fill:none;stroke:#fff;stroke-width:3.44449;stroke-miterlimit:10;}
          .rsc1{fill:none;stroke:#fff;stroke-width:2.21432;stroke-linejoin:round;stroke-miterlimit:10;}
          .rsc2{fill:none;stroke:#fff;stroke-width:1.72225;stroke-linejoin:round;stroke-miterlimit:10;}
          .rsc3{fill:#fff;}
        </style>
        <path class="rsc0" d="M5.01186,93.42575c0,0-3.69034-36.90344,0-55.35516c0,0,3.32131-10.33296,16.23751-7.38068
        c0-33.58376,113.06538-40.47275,122.51942,1.47614c0,0,15.86848-8.11876,19.55882,6.64262s0.83012,55.56899,0.83012,55.56899
        s0.28703,6.41613-6.06888,6.23289L13.91222,100.7638C13.91222,100.7638,5.01186,100.08266,5.01186,93.42575z"/>
        <path class="rsc0" d="M34.08711,55.50408c0,0,21.49638-1.14817,38.05345-1.14817
        c16.65768,0,14.44337,22.06345,0.91143,22.06345c0,0-53.0709,2.86254-53.0709-2.79626v-9.7799"/>
        <polygon class="rsc1" points="34.05379,54.89156 21.06965,61.37805 37.35989,55.66811"/>
        <path class="rsc0" d="M134.14143,55.01201c0,0-21.96984-0.79974-38.05344-0.79974
        c-14.91684,0-12.00706,12.52023-11.35863,17.07907c0,0,1.94154,4.62808,7.26519,5.03814
        c6.11917,0.37463,52.15231,0.37463,52.15231,0.37463s3.02574-0.24603,2.82941-4.05958l0.19633-8.69883"/>
        <polygon class="rsc1" points="131.70158,55.56559 146.23859,61.53526 134.2747,54.3918"/>
        <path class="rsc0" d="M27.80872,58.13946c0,0,2.7871-26.34803-6.55934-27.44956"/>
        <path class="rsc0" d="M141.00992,58.36717c0,0-1.18671-22.27027,2.75888-26.20113"/>
        <path class="rsc0" d="M43.03569,77.14787c10.82555,0-12.15877,0.95855-16.7214,1.21699
        c-0.76488,0.12302-6.33639-0.31312-6.33639,6.08938l1.27147,11.93271c0,0,0.06441,3.8564,6.94834,3.69053H142.4415
        c0,0,4.16573,0.0052,4.16573-4.42863l0.56538-10.39499c0,0,0.60954-8.10598-5.80484-8.49857"/>
        <polygon class="rsc2" points="144.83409,80.46227 125.45811,80.67704 146.23859,81.84417 		"/>
        <path class="rsc3" d="M203.19661,60.03906c-0.99756,0.81445-1.49609,2.06055-1.49609,3.73926v25.35156h-8.34521V50.47266h7.24316
        l0.47217,5.27539c0.47266-2.20508,1.36426-3.80469,2.67725-4.80273c1.31104-0.99609,2.88574-1.31152,4.72363-0.94531v9.29102
        C205.95198,58.97559,204.19319,59.22559,203.19661,60.03906z M227.68196,86.84668
        c-1.78516,1.94238-4.09424,2.91309-6.92871,2.91309c-2.83398,0-5.18408-0.9707-7.04639-2.91309
        c-1.86377-1.94141-2.79492-4.4873-2.79492-7.63672V60.39258c0-1.57422,0.24854-3.00391,0.74805-4.29004
        c0.49805-1.28613,1.19336-2.40137,2.08643-3.34668c0.8916-0.94434,1.92871-1.67871,3.10986-2.2041
        c1.18066-0.52441,2.47998-0.78711,3.89697-0.78711c2.83447,0,5.14355,0.9834,6.92871,2.95215
        c1.78369,1.96777,2.67676,4.52734,2.67676,7.67578v10.62891h-11.25879v9.36914c0,0.62988,0.1416,1.18066,0.42432,1.65332
        c0.2832,0.47266,0.75781,0.70898,1.42725,0.70898c0.71826,0,1.22021-0.23633,1.50342-0.70898
        c0.28271-0.47266,0.42432-1.02344,0.42432-1.65332v-5.58984h7.47949v4.40918
        C230.35872,82.35938,229.46565,84.90527,227.68196,86.84668z M222.6429,59.19336c0-0.625-0.15479-1.15723-0.4624-1.60059
        c-0.30762-0.44238-0.74414-0.66406-1.30908-0.66406c-0.56592,0-1.00244,0.22168-1.31006,0.66406
        c-0.30762,0.44336-0.46143,0.97559-0.46143,1.60059v7.57715h3.54297V59.19336z M247.19173,89.16895l-0.38965-2.99121
        c-1.32031,2.38867-3.2998,3.58203-5.93701,3.58203c-2.2168,0-3.90479-0.82715-5.06592-2.48047s-1.74072-3.68652-1.74072-6.10156
        V58.03125c0-1.10254,0.1709-2.15137,0.51562-3.14941c0.34326-0.99609,0.80566-1.87598,1.38623-2.6377
        c0.58057-0.75977,1.25488-1.36426,2.021-1.81055c0.7666-0.44531,1.59961-0.66895,2.49756-0.66895
        c1.10938,0,2.06055,0.24902,2.85254,0.74707c0.79248,0.5,1.58594,1.2207,2.37939,2.16602V36.69531h8.18799v52.47363H247.19173z
        M245.71077,59.29785c0-0.63184-0.15771-1.1582-0.47266-1.5791s-0.73535-0.63281-1.25977-0.63281
        c-0.5249,0-0.94434,0.21191-1.25928,0.63281s-0.47266,0.94727-0.47266,1.5791v21.08594c0,0.63184,0.15771,1.15918,0.47266,1.58008
        s0.73438,0.63086,1.25928,0.63086c0.52441,0,0.94482-0.20996,1.25977-0.63086s0.47266-0.94824,0.47266-1.58008V59.29785z
        M286.17709,83.22461c-0.36768,1.31348-0.94482,2.45508-1.73193,3.4248c-0.7876,0.97266-1.78516,1.74609-2.99219,2.32324
        c-1.20801,0.57715-2.625,0.86621-4.25146,0.86621c-3.04443,0-5.43213-0.93164-7.16455-2.79492
        c-1.73193-1.86328-2.59814-4.39551-2.59814-7.59766v-2.44141h7.16455v3.22852c0,1.83789,0.70898,2.75586,2.12598,2.75586
        c1.57471,0,2.36182-0.83984,2.36182-2.51953c0-0.94531-0.15723-1.75781-0.47217-2.44141
        c-0.31494-0.68066-0.74805-1.32422-1.29932-1.92871c-0.55127-0.60254-1.20801-1.25977-1.96826-1.96777
        c-0.76123-0.70898-1.58789-1.58691-2.47998-2.6377c-1.3125-1.36426-2.51953-2.92578-3.62158-4.68457
        c-1.10205-1.75781-1.65332-3.89746-1.65332-6.41699c0-3.47656,0.90527-6.11719,2.71631-7.92188
        c1.81055-1.80371,4.08057-2.70605,6.81006-2.70605c1.46875,0,2.78125,0.2793,3.93652,0.83594
        c1.15381,0.55762,2.13818,1.31445,2.95264,2.26855c0.81299,0.95605,1.4292,2.05566,1.8501,3.29785
        c0.41943,1.24316,0.62988,2.57227,0.62988,3.99023v2.44043h-7.08594v-3.07031c0-0.73438-0.1709-1.35156-0.51172-1.85059
        c-0.3418-0.49805-0.85352-0.74805-1.53516-0.74805c-1.36572,0-2.04688,0.78711-2.04688,2.3623
        c0,1.3125,0.34033,2.47949,1.02344,3.50293c0.68115,1.02344,1.62598,2.24414,2.83398,3.66113
        c0.83936,0.78711,1.70508,1.58789,2.59814,2.40137c0.89209,0.81445,1.70508,1.70605,2.44092,2.67676
        c0.73438,0.97168,1.33838,2.07422,1.81055,3.30664c0.47266,1.23438,0.70898,2.66504,0.70898,4.29102
        C286.72836,80.54785,286.54379,81.91406,286.17709,83.22461z M308.73373,83.50098c-0.5,1.33789-1.19531,2.46777-2.08789,3.38477
        c-0.89258,0.91895-1.94141,1.62793-3.14844,2.12598c-1.20801,0.49805-2.49365,0.74805-3.85791,0.74805
        c-2.83398,0-5.18408-0.95703-7.04639-2.87402c-1.86377-1.91504-2.79492-4.55273-2.79492-7.91211V60.62891
        c0-1.62598,0.24854-3.10938,0.74805-4.44824c0.49805-1.33789,1.19336-2.47949,2.08643-3.4248
        c0.8916-0.94434,1.92871-1.67871,3.10986-2.2041c1.18066-0.52441,2.47998-0.78711,3.89697-0.78711
        c1.36426,0,2.6499,0.2627,3.85791,0.78711c1.20703,0.52539,2.25586,1.25977,3.14844,2.2041
        c0.89258,0.94531,1.58789,2.08691,2.08789,3.4248c0.49805,1.33887,0.74805,2.82227,0.74805,4.44824v18.34473
        C309.48178,80.6543,309.23178,82.16211,308.73373,83.50098z M301.29282,59.12695c0-0.62695-0.16699-1.15137-0.50049-1.56934
        c-0.33447-0.41895-0.73193-0.62891-1.19336-0.62891c-0.5127,0-0.93604,0.20996-1.26953,0.62891
        c-0.33447,0.41797-0.50049,0.94238-0.50049,1.56934v21.42773c0,0.62793,0.16602,1.15234,0.50049,1.57031
        c0.3335,0.41895,0.75684,0.62793,1.26953,0.62793c0.46143,0,0.85889-0.20898,1.19336-0.62793
        c0.3335-0.41797,0.50049-0.94238,0.50049-1.57031V59.12695z M324.20346,57.87402v31.25586h-8.3457V57.87402h-3.46387v-7.16504
        h3.46387v-2.75586c0-3.51562,0.79492-6.27148,2.38477-8.2666c1.58887-1.99414,4.25879-2.99121,8.00781-2.99121h1.88965v6.77051
        h-0.86621c-1.25977,0-2.11328,0.28906-2.55859,0.86621c-0.44629,0.57812-0.66895,1.44434-0.66895,2.59766v3.7793h4.25098v7.16504
        H324.20346z M349.16147,89.75977c-2.7832,0-4.75098-1.41699-5.90527-4.25195c-1.25977,2.83496-3.41211,4.25195-6.45605,4.25195
        c-1.99512,0-3.66113-0.81055-4.99902-2.43457c-1.33887-1.62402-2.00781-3.92773-2.00781-6.91211v-2.19824
        c0-1.67578,0.24902-3.15527,0.74805-4.43848c0.49805-1.28223,1.23242-2.44727,2.2041-3.49512
        c0.9707-1.04688,2.16504-1.98926,3.58301-2.82715c1.41699-0.83789,3.07031-1.64941,4.95996-2.43555v-6.12598
        c0-0.57617-0.13184-1.04688-0.39355-1.41406c-0.26367-0.36621-0.65723-0.5498-1.18164-0.5498
        c-0.4209,0-0.77441,0.18457-1.0625,0.55078c-0.28906,0.36816-0.43359,0.84082-0.43359,1.41797v4.01465h-7.32129V59.9209
        c0-3.00293,0.77344-5.44629,2.32227-7.33105c1.54785-1.88281,3.89746-2.8252,7.04688-2.8252
        c2.99121,0,5.24805,0.97168,6.77051,2.91309c1.52148,1.94238,2.2832,4.43555,2.2832,7.47949v21.49316
        c0,1.15527,0.49805,1.81055,1.49609,1.96875v6.14062H349.16147z M341.28842,70.94336
        c-1.20801,0.73535-2.11328,1.53516-2.7168,2.40137c-0.60352,0.86523-0.90527,1.98145-0.90527,3.3457v4.01562
        c0,1.62695,0.62988,2.44043,1.88965,2.44043c0.57715,0,1.00977-0.20898,1.29883-0.62988
        c0.28809-0.41992,0.43359-0.94531,0.43359-1.5752V70.94336z M380.06186,86.88574c-1.65332,1.91699-3.87207,2.87402-6.65332,2.87402
        c-2.83398,0-5.18359-0.95703-7.0459-2.87402c-1.86426-1.91504-2.79492-4.55273-2.79492-7.91211V60.62891
        c0-1.62598,0.24805-3.10938,0.74707-4.44824c0.49902-1.33789,1.19336-2.47949,2.08691-3.4248
        c0.8916-0.94434,1.92871-1.67871,3.10938-2.2041c1.18164-0.52441,2.48047-0.78711,3.89746-0.78711
        c2.78125,0,5,0.99512,6.65332,2.98535s2.47949,4.64258,2.47949,7.95801v4.40918h-6.84961v-5.58984
        c0-0.78809-0.18457-1.41797-0.55078-1.88965c-0.36816-0.47266-0.84082-0.70898-1.41699-0.70898
        c-0.68359,0-1.20801,0.23633-1.5752,0.70605c-0.36719,0.47168-0.55078,1.09961-0.55078,1.88477v20.64258
        c0,0.78613,0.18359,1.41406,0.55078,1.88477s0.8916,0.70605,1.5752,0.70605c0.57617,0,1.04883-0.23633,1.41699-0.70898
        c0.36621-0.47266,0.55078-1.10254,0.55078-1.88965v-5.58984h6.84961v4.40918
        C382.54135,82.33301,381.71518,84.9707,380.06186,86.88574z M404.26987,89.75977c-2.7832,0-4.75098-1.41699-5.90527-4.25195
        c-1.25977,2.83496-3.41211,4.25195-6.45605,4.25195c-1.99512,0-3.66113-0.81055-4.99902-2.43457
        c-1.33887-1.62402-2.00781-3.92773-2.00781-6.91211v-2.19824c0-1.67578,0.24902-3.15527,0.74805-4.43848
        c0.49805-1.28223,1.23242-2.44727,2.2041-3.49512c0.9707-1.04688,2.16504-1.98926,3.58301-2.82715
        c1.41699-0.83789,3.07031-1.64941,4.95996-2.43555v-6.12598c0-0.57617-0.13184-1.04688-0.39355-1.41406
        c-0.26367-0.36621-0.65723-0.5498-1.18164-0.5498c-0.4209,0-0.77441,0.18457-1.0625,0.55078
        c-0.28906,0.36816-0.43359,0.84082-0.43359,1.41797v4.01465h-7.32129V59.9209c0-3.00293,0.77344-5.44629,2.32227-7.33105
        c1.54785-1.88281,3.89746-2.8252,7.04688-2.8252c2.99121,0,5.24805,0.97168,6.77051,2.91309
        c1.52148,1.94238,2.2832,4.43555,2.2832,7.47949v21.49316c0,1.15527,0.49805,1.81055,1.49609,1.96875v6.14062H404.26987z
        M396.39682,70.94336c-1.20801,0.73535-2.11328,1.53516-2.7168,2.40137c-0.60352,0.86523-0.90527,1.98145-0.90527,3.3457v4.01562
        c0,1.62695,0.62988,2.44043,1.88965,2.44043c0.57715,0,1.00977-0.20898,1.29883-0.62988
        c0.28809-0.41992,0.43359-0.94531,0.43359-1.5752V70.94336z M419.62143,57.87402v31.25586h-8.3457V57.87402h-3.46387v-7.16504
        h3.46387v-2.75586c0-3.51562,0.79492-6.27148,2.38477-8.2666c1.58887-1.99414,4.25879-2.99121,8.00781-2.99121h1.88965v6.77051
        h-0.86621c-1.25977,0-2.11328,0.28906-2.55859,0.86621c-0.44629,0.57812-0.66895,1.44434-0.66895,2.59766v3.7793h4.25098v7.16504
        H419.62143z M442.92612,86.84668c-1.78516,1.94238-4.09473,2.91309-6.92871,2.91309s-5.18359-0.9707-7.0459-2.91309
        c-1.86426-1.94141-2.79492-4.4873-2.79492-7.63672V60.39258c0-1.57422,0.24805-3.00391,0.74707-4.29004
        s1.19336-2.40137,2.08691-3.34668c0.8916-0.94434,1.92871-1.67871,3.10938-2.2041c1.18164-0.52441,2.48047-0.78711,3.89746-0.78711
        c2.83398,0,5.14355,0.9834,6.92871,2.95215c1.7832,1.96777,2.67676,4.52734,2.67676,7.67578v10.62891h-11.25879v9.36914
        c0,0.62988,0.1416,1.18066,0.4248,1.65332c0.28223,0.47266,0.75781,0.70898,1.42676,0.70898
        c0.71875,0,1.2207-0.23633,1.50293-0.70898c0.2832-0.47266,0.4248-1.02344,0.4248-1.65332v-5.58984h7.47949v4.40918
        C445.60287,82.35938,444.70932,84.90527,442.92612,86.84668z M437.88705,59.19336c0-0.625-0.15527-1.15723-0.46289-1.60059
        c-0.30762-0.44238-0.74414-0.66406-1.30859-0.66406c-0.56543,0-1.00293,0.22168-1.31055,0.66406
        c-0.30664,0.44336-0.46094,0.97559-0.46094,1.60059v7.57715h3.54297V59.19336z M191.15071,101.72559v-6.29688h256.18262v6.29688
        H191.15071z"/>
      </svg>
    `}
  />
);

export default RSCLogo;
