import SVGInline from 'react-svg-inline';

const SlackIcon = () => (
  <SVGInline
    component="div"
    className="slack-icon"
    classSuffix=""
    accessibilityLabel="Slack"
    fill="#121415"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 496.39996 125.59999" style="enable-background:new 0 0 496.39996 125.59999;" xml:space="preserve">
        <path d="M158.8,98.89999L165,84.5c6.7,5,15.60001,7.60001,24.39999,7.60001c6.49998,0,10.59999-2.5,10.59999-6.3
          c-0.10001-10.60001-38.89999-2.3-39.2-28.9c-0.10001-13.5,11.89999-23.9,28.89999-23.9c10.09999,0,20.2,2.5,27.40001,8.2L211.3,55.9
          C204.7,51.7,196.50002,48.7,188.7,48.7c-5.3,0-8.8,2.5-8.8,5.7c0.10001,10.40001,39.2,4.7,39.59999,30.1
          c0,13.8-11.70001,23.5-28.5,23.5C178.7,108,167.40001,105.09999,158.8,98.89999 M396.69998,79.29999
          c-3.10001,5.39999-8.89999,9.10001-15.60001,9.10001c-9.89999,0-17.89999-8-17.89999-17.89999s8-17.9,17.89999-17.9
          c6.70001,0,12.5,3.7,15.60001,9.1l17.10001-9.5C407.39999,40.8,395.09998,33,381.09998,33c-20.70001,0-37.5,16.8-37.5,37.5
          s16.79999,37.5,37.5,37.5c14.10001,0,26.29999-7.7,32.70001-19.2L396.69998,79.29999z M228.09999,1.9h21.39999v104.7h-21.39999V1.9z
            M422.19998,1.9v104.7h21.39999V75.2L469,106.59999h27.39996l-32.29999-37.3L494,34.5h-26.20001l-24.20001,28.9V1.9H422.19998z
            M313.09998,79.5c-3.10001,5.10001-9.5,8.89999-16.70001,8.89999c-9.89999,0-17.89999-8-17.89999-17.89999s8-17.9,17.89999-17.9
          c7.20001,0,13.60001,4,16.70001,9.2V79.5z M313.09998,34.5V43c-3.5-5.9-12.20001-10-21.29999-10
          c-18.79999,0-33.60001,16.6-33.60001,37.4S272.99997,108,291.79999,108c9.10001,0,17.79999-4.10001,21.29999-10v8.5h21.39999v-72
          C334.49997,34.5,313.09998,34.5,313.09998,34.5z M26.5,79.39999c0,7.3-5.9,13.2-13.2,13.2S0.1,86.7,0.1,79.39999S6,66.2,13.3,66.2
          h13.2V79.39999z M33.1,79.39999c0-7.3,5.9-13.2,13.2-13.2s13.2,5.89999,13.2,13.2v33c0,7.3-5.9,13.2-13.2,13.2
          s-13.2-5.89999-13.2-13.2V79.39999z M46.3,26.4c-7.3,0-13.2-5.9-13.2-13.2C33.1,5.9,39,0,46.3,0s13.2,5.9,13.2,13.2v13.2H46.3z
            M46.3,33.09999c7.3,0,13.2,5.9,13.2,13.2c0,7.3-5.9,13.2-13.2,13.2H13.2c-7.3,0-13.2-5.9-13.2-13.2c0-7.3,5.9-13.2,13.2-13.2H46.3z
            M99.2,46.3c0-7.3,5.89999-13.2,13.2-13.2s13.2,5.9,13.2,13.2c0,7.3-5.89999,13.2-13.2,13.2H99.2V46.3z M92.60001,46.3
          c0,7.3-5.89999,13.2-13.2,13.2s-13.2-5.9-13.2-13.2V13.2c0-7.3,5.89999-13.2,13.2-13.2s13.2,5.9,13.2,13.2V46.3z M79.40001,99.2
          c7.3,0,13.2,5.89999,13.2,13.2s-5.89999,13.2-13.2,13.2s-13.2-5.89999-13.2-13.2V99.2H79.40001z M79.40001,92.59999
          c-7.3,0-13.2-5.89999-13.2-13.2s5.89999-13.2,13.2-13.2h33.10001c7.3,0,13.2,5.89999,13.2,13.2s-5.89999,13.2-13.2,13.2H79.40001z"
          />
      </svg>
      <span class="tooltip">Slack</span>
    `}
  />
);

export default SlackIcon;
