import SVGInline from 'react-svg-inline';

const EmotionIcon = () => (
  <SVGInline
    component="div"
    className="emotion-icon"
    classSuffix=""
    accessibilityLabel="Emotion CSS-in-JS library"
    fill="#121415"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 57.06 12.6" style="enable-background:new 0 0 57.06 12.6;" xml:space="preserve">
        <path d="M4.75119,11.59785c-1.17578,0-2.09522-0.34277-2.7583-1.02881s-0.99463-1.63037-0.99463-2.8335
        c0-1.23486,0.30762-2.20557,0.92285-2.91211s1.46045-1.05957,2.53613-1.05957c0.99805,0,1.78662,0.30322,2.36523,0.90918
        S7.69064,6.11299,7.69064,7.175v0.86816H2.65256c0.02295,0.73389,0.22119,1.29785,0.59473,1.69189s0.8999,0.59131,1.5791,0.59131
        c0.44678,0,0.8623-0.04199,1.24756-0.12646s0.79883-0.22461,1.24072-0.42041v1.30566
        c-0.39209,0.18701-0.78857,0.31885-1.18945,0.39648S5.26633,11.59785,4.75119,11.59785z M4.45725,4.98066
        c-0.51025,0-0.91943,0.16162-1.22705,0.48535S2.73898,6.26143,2.6799,6.88105h3.43164
        C6.10227,6.25654,5.95188,5.78389,5.66037,5.4626S4.9675,4.98066,4.45725,4.98066z M15.88693,11.46113h-1.61328V6.79902
        c0-0.57861-0.10938-1.01074-0.32812-1.29541s-0.56055-0.42725-1.02539-0.42725c-0.61963,0-1.07422,0.20166-1.36377,0.60498
        s-0.43408,1.07422-0.43408,2.01318v3.7666H9.51584V3.90059h1.25781l0.22559,0.99121h0.08203
        c0.20947-0.35986,0.51367-0.63818,0.9126-0.83398c0.39893-0.1958,0.8374-0.29395,1.31592-0.29395
        c1.16211,0,1.93213,0.39648,2.31055,1.18945h0.10938c0.22314-0.37354,0.5376-0.66553,0.94336-0.875
        c0.40576-0.20947,0.87061-0.31445,1.39453-0.31445c0.90234,0,1.55957,0.22803,1.97217,0.68359s0.61865,1.15088,0.61865,2.08496
        v4.92871h-1.60645V6.79902c0-0.57861-0.11035-1.01074-0.33154-1.29541s-0.56396-0.42725-1.02881-0.42725
        c-0.62451,0-1.08105,0.19482-1.37061,0.58447s-0.43408,0.98779-0.43408,1.79443V11.46113z M29.60666,7.66719
        c0,1.23486-0.31689,2.19873-0.9502,2.8916s-1.51514,1.03906-2.64551,1.03906c-0.70654,0-1.33057-0.15967-1.87305-0.47852
        s-0.95947-0.77686-1.25098-1.37402s-0.4375-1.28955-0.4375-2.07812c0-1.22607,0.31445-2.18311,0.94336-2.87109
        s1.51514-1.03223,2.65918-1.03223c1.09375,0,1.95947,0.35205,2.59766,1.05615S29.60666,6.47334,29.60666,7.66719z M24.10373,7.66719
        c0,1.74561,0.64502,2.61816,1.93457,2.61816c1.27588,0,1.91406-0.87256,1.91406-2.61816c0-1.72705-0.64258-2.59082-1.92773-2.59082
        c-0.67432,0-1.16309,0.22314-1.46631,0.66992S24.10373,6.8332,24.10373,7.66719z M34.26877,10.29902
        c0.39209,0,0.78369-0.06152,1.17578-0.18457v1.20996c-0.17773,0.07764-0.40674,0.14258-0.68701,0.19482
        s-0.5708,0.07861-0.87158,0.07861c-1.52197,0-2.2832-0.80225-2.2832-2.40625V5.11738h-1.03223V4.40645l1.10742-0.58789
        l0.54687-1.59961h0.99121v1.68164h2.15332v1.2168h-2.15332v4.04688c0,0.38721,0.09668,0.67334,0.29053,0.85791
        S33.95432,10.29902,34.26877,10.29902z M36.85959,1.89766c0-0.28711,0.07861-0.5083,0.23584-0.66309
        s0.38184-0.23242,0.67334-0.23242c0.28271,0,0.50244,0.07764,0.65967,0.23242s0.23584,0.37598,0.23584,0.66309
        c0,0.27344-0.07861,0.48877-0.23584,0.646s-0.37695,0.23584-0.65967,0.23584c-0.2915,0-0.51611-0.07861-0.67334-0.23584
        S36.85959,2.17109,36.85959,1.89766z M38.56174,11.46113h-1.60645V3.90059h1.60645V11.46113z M47.56467,7.66719
        c0,1.23486-0.31689,2.19873-0.9502,2.8916s-1.51514,1.03906-2.64551,1.03906c-0.70654,0-1.33057-0.15967-1.87305-0.47852
        s-0.95947-0.77686-1.25098-1.37402s-0.4375-1.28955-0.4375-2.07812c0-1.22607,0.31445-2.18311,0.94336-2.87109
        s1.51514-1.03223,2.65918-1.03223c1.09375,0,1.95947,0.35205,2.59766,1.05615S47.56467,6.47334,47.56467,7.66719z M42.06174,7.66719
        c0,1.74561,0.64502,2.61816,1.93457,2.61816c1.27588,0,1.91406-0.87256,1.91406-2.61816c0-1.72705-0.64258-2.59082-1.92773-2.59082
        c-0.67432,0-1.16309,0.22314-1.46631,0.66992S42.06174,6.8332,42.06174,7.66719z M56.06174,11.46113h-1.61328V6.8127
        c0-0.5835-0.11719-1.01855-0.35205-1.30566s-0.60742-0.43066-1.11768-0.43066c-0.6792,0-1.17578,0.20068-1.49023,0.60156
        s-0.47168,1.07324-0.47168,2.0166v3.7666h-1.60645V3.90059h1.25781l0.22559,0.99121h0.08203
        c0.22803-0.35986,0.55127-0.63818,0.9707-0.83398c0.41943-0.1958,0.88428-0.29395,1.39453-0.29395
        c1.81396,0,2.7207,0.92285,2.7207,2.76855V11.46113z"/>
      </svg>
      <span class="tooltip">Emotion CSS-in-JS library</span>
    `}
  />
);

export default EmotionIcon;
