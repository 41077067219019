import SVGInline from 'react-svg-inline';

const IOTGALogo = () => (
  <SVGInline
    className="iotga-logo"
    classSuffix=""
    accessibilityLabel="IoT Global Awards logo"
    fill="#fff"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 579.5 107" style="enable-background:new 0 0 579.5 107;" xml:space="preserve">
        <path d="M2.44451,63.66455V3.03857h12.93945v60.62598H2.44451z M36.56707,64.26904
        c-10.02929,0-15.04394-5.22754-15.04394-15.68262V28.37158c0-4.81348,1.34326-8.62988,4.02979-11.45117
        c2.68652-2.82031,6.35791-4.23145,11.01416-4.23145c4.67871,0,8.36182,1.41113,11.04834,4.23145
        c2.68604,2.82129,4.0293,6.6377,4.0293,11.45117v20.21484c0,5.26172-1.28174,9.19043-3.84473,11.78711
        C45.2365,62.97119,41.49187,64.26904,36.56707,64.26904z M33.74627,26.45752v24.61426c0,1.34375,0.26318,2.32324,0.78955,2.93848
        c0.52539,0.61621,1.20312,0.92383,2.03125,0.92383s1.50537-0.30762,2.03174-0.92383
        c0.52588-0.61523,0.78906-1.59473,0.78906-2.93848V26.45752c0-2.93262-0.93994-4.39941-2.8208-4.39941
        C34.6867,22.05811,33.74627,23.5249,33.74627,26.45752z M52.01385,3.03857h29.30469v11.68359h-7.9541v48.94238H59.96795V14.72217
        h-7.9541V3.03857z M132.23798,63.66455l-2.2832-5.1377c-2.38526,3.88184-6.06446,5.82227-11.03712,5.82227
        c-9.6665,0-14.5-6.25391-14.5-18.76172V19.32666c0-2.86621,0.38086-5.36621,1.14209-7.49707
        c0.76074-2.13086,1.69336-3.77344,2.79688-4.92871c1.104-1.1543,2.45508-2.07422,4.05322-2.75879
        c1.59863-0.68555,3.05078-1.12305,4.35791-1.31348c1.30615-0.18945,2.72119-0.28516,4.24316-0.28516
        c1.87744,0,3.57764,0.13379,5.1001,0.39941c1.52197,0.2666,2.83497,0.67969,3.93897,1.2373
        c1.10352,0.55859,2.07373,1.18652,2.91113,1.88379c0.8374,0.69824,1.52832,1.56055,2.07422,2.58789
        c0.54541,1.02734,0.98975,2.04297,1.33203,3.04492c0.34277,1.00195,0.60254,2.20703,0.78027,3.61523
        c0.17725,1.4082,0.29785,2.7207,0.36133,3.93945c0.06299,1.21777,0.09521,2.65137,0.09521,4.2998h-12.78761v-4.79492
        c0-0.96387-0.10156-1.76367-0.3042-2.39746c-0.20361-0.63379-0.48877-1.08496-0.85645-1.35156
        c-0.36816-0.2666-0.7041-0.44336-1.0083-0.53223c-0.30469-0.08887-0.64697-0.13379-1.02783-0.13379
        c-0.76123,0-1.37646,0.10156-1.8457,0.30469c-0.46973,0.20312-0.8374,0.45703-1.10352,0.76074
        c-0.2666,0.30469-0.46338,0.85645-0.58984,1.65625c-0.12744,0.79883-0.20361,1.55371-0.22852,2.26367
        c-0.02539,0.71094-0.03809,1.82715-0.03809,3.34961v23.44336c0,0.88867,0.03174,1.63672,0.09521,2.24609
        c0.06299,0.6084,0.20947,1.26855,0.4375,1.97852c0.22852,0.71094,0.60254,1.25,1.12305,1.61719
        c0.51953,0.36816,1.18555,0.55176,1.99805,0.55176c1.34424,0,2.23877-0.52539,2.68262-1.5791
        c0.44385-1.05273,0.66602-2.75879,0.66602-5.11914v-7.00195h-3.57715V28.34717h16.21241v35.31738H132.23798z M144.20624,63.66455
        V3.03857h13.39648v48.79004h13.77686v11.83594H144.20624z M174.97528,47.29932V18.41357c0-5.04883,1.36377-8.96289,4.09131-11.74121
        c2.72705-2.77734,6.83154-4.20508,12.31152-4.28125c5.63281,0.07617,9.8125,1.50391,12.54053,4.28125
        c2.72705,2.77832,4.09082,6.69238,4.09082,11.74121v28.88574c0,2.46191-0.31104,4.7002-0.93213,6.71777
        c-0.62207,2.0166-1.56689,3.8125-2.83545,5.38477c-1.26904,1.57324-2.9873,2.79199-5.15674,3.65332
        c-2.16943,0.8623-4.66211,1.25684-7.47852,1.18066c-2.96826,0.07617-5.5376-0.31836-7.70654-1.18066
        c-2.16943-0.86133-3.88867-2.08008-5.15674-3.65332c-1.26904-1.57227-2.21436-3.36816-2.83545-5.38477
        C175.28583,51.99951,174.97528,49.76123,174.97528,47.29932z M191.49237,52.36182c0.48193,0,0.90723-0.0957,1.2749-0.28613
        s0.65967-0.48145,0.87549-0.875c0.21533-0.39355,0.38672-0.76758,0.51367-1.12305c0.12695-0.35449,0.21533-0.83008,0.2666-1.42676
        c0.05029-0.5957,0.08203-1.05273,0.09521-1.37012c0.01221-0.31738,0.01904-0.75488,0.01904-1.31348c0-0.12598,0-0.22754,0-0.30371
        V21.30615c0-0.15234,0.00586-0.45703,0.01904-0.91309c0.01221-0.45703,0.01221-0.7998,0-1.02832
        c-0.01318-0.22754-0.02588-0.56348-0.03809-1.00781c-0.01318-0.44434-0.05127-0.78613-0.11426-1.02832
        c-0.06348-0.24023-0.13965-0.54492-0.22852-0.91309c-0.08887-0.36719-0.20898-0.64648-0.36133-0.83691
        s-0.33008-0.39355-0.53271-0.60938c-0.20361-0.21484-0.45703-0.36719-0.76123-0.45605
        c-0.30469-0.08887-0.64697-0.13379-1.02783-0.13379c-0.53271,0-0.99609,0.08984-1.38916,0.2666
        c-0.39355,0.17773-0.7041,0.46289-0.93213,0.85645c-0.22852,0.39355-0.41895,0.74805-0.5708,1.06543
        c-0.15234,0.31738-0.25391,0.78711-0.30469,1.4082c-0.05127,0.62207-0.07617,1.06543-0.07617,1.33203s0,0.7168,0,1.35059
        c0,0.10254,0,0.19043,0,0.2666s0,0.15234,0,0.22852v24.35742c0,0.55762,0.00586,1.00195,0.01904,1.33203
        c0.0127,0.33008,0.04395,0.81152,0.09521,1.44629c0.05078,0.63379,0.14551,1.14746,0.28564,1.54102
        c0.13916,0.39355,0.32324,0.80566,0.55176,1.2373c0.22803,0.43164,0.53857,0.75488,0.93213,0.96973
        C190.49628,52.25342,190.95966,52.36182,191.49237,52.36182z M214.612,63.66455V3.03857h18.15381
        c4.74414,0,8.2583,1.0459,10.54199,3.13965c2.2832,2.09277,3.42529,5.66406,3.42529,10.71289c0,0.15234,0,0.36816,0,0.64746
        c0,0.88867,0,1.54785,0,1.97852c0,0.43164-0.04492,1.10352-0.1333,2.01758c-0.08936,0.91309-0.21582,1.62988-0.38086,2.15039
        c-0.16504,0.51953-0.4126,1.16016-0.74219,1.92188c-0.32959,0.76074-0.73584,1.38281-1.21777,1.86426
        c-0.48193,0.48242-1.09082,0.95215-1.82666,1.4082c-0.73633,0.45703-1.58594,0.7998-2.5498,1.02734
        c1.39502,0.33008,2.58789,0.90137,3.57715,1.71289c0.98975,0.8125,1.73779,1.66895,2.24561,2.56934
        c0.50732,0.90039,0.90674,2.01074,1.19873,3.33008c0.2915,1.31934,0.46289,2.42285,0.51416,3.31055
        c0.05029,0.88867,0.07568,1.9541,0.07568,3.19727c0,3.19629-0.22217,5.93652-0.66602,8.2207
        c-0.44385,2.2832-1.21191,4.31934-2.30225,6.1084c-1.09131,1.78809-2.60693,3.12012-4.54785,3.99512
        c-1.94092,0.87598-4.34521,1.31348-7.21191,1.31348H214.612z M227.7038,25.87354h2.74023c0.65967,0,1.19873-0.13965,1.61768-0.41895
        c0.41846-0.2793,0.71631-0.67871,0.89404-1.19922c0.17725-0.51953,0.2915-0.98926,0.34277-1.4082
        c0.05029-0.41797,0.07617-0.91895,0.07617-1.50293v-6.01367c0-1.92773-0.95166-2.8916-2.85449-2.8916h-2.81641V25.87354z
        M227.7038,35.31104v17.58301c0.60889,0.05078,1.05273,0.07617,1.33203,0.07617c3.47607,0,5.21387-1.49707,5.21387-4.49121v-7.57324
        c0-1.92773-0.32324-3.34277-0.97021-4.24316s-1.68115-1.35156-3.10156-1.35156H227.7038z M278.22528,3.03857l6.69824,60.62598
        h-12.93994l-0.95117-9.13379H264.258l-0.8374,9.13379h-13.01562l6.85059-60.62598H278.22528z M268.216,14.41748h-1.21777
        l-1.78857,29.07617h4.79492L268.216,14.41748z M289.81317,63.66455V3.03857h13.39648v48.79004h13.77686v11.83594H289.81317z
        M365.66083,3.03857l6.69824,60.62598h-12.93994l-0.95117-9.13379h-6.77441l-0.8374,9.13379h-13.01562l6.85059-60.62598H365.66083z
        M355.65155,14.41748h-1.21777l-1.78857,29.07617h4.79492L355.65155,14.41748z M390.34052,41.28662l3.76758-38.24805h12.48438
        l3.57617,38.24805l3.57812-38.24805h13.24414l-7.30664,60.62598h-16.06055l-3.34961-29.22852l-3.19727,29.22852h-16.32617
        l-7.19336-60.62598h13.0918L390.34052,41.28662z M455.93231,3.03857l6.69824,60.62598H449.6911l-0.95117-9.13379h-6.77441
        l-0.83691,9.13379H428.112l6.85059-60.62598H455.93231z M445.92352,14.41748h-1.21777l-1.78906,29.07617h4.7959L445.92352,14.41748z
        M481.06903,36.07275v27.5918h-13.54785V3.03857h20.55078c2.10547,0,3.90723,0.28516,5.4043,0.85645
        c1.49707,0.57031,2.67676,1.31934,3.53906,2.24512c0.8623,0.92676,1.54199,2.15625,2.03613,3.69141
        c0.49512,1.53516,0.81836,3.07031,0.9707,4.60547s0.22852,3.39355,0.22852,5.5752c0,1.1416-0.01953,2.07422-0.05762,2.79688
        c-0.03809,0.72363-0.17773,1.63672-0.41797,2.74023c-0.24219,1.10352-0.57812,2.02344-1.00879,2.75977
        c-0.43164,0.73633-1.08496,1.45898-1.95996,2.16895c-0.875,0.71094-1.93555,1.25586-3.17773,1.63672
        c1.24219,0.22852,2.27051,0.65332,3.08203,1.27441c0.8125,0.62207,1.39551,1.38965,1.75098,2.30273s0.5957,1.75684,0.72363,2.53125
        c0.12598,0.77441,0.18945,1.64258,0.18945,2.60645c0,10.02246-0.05078,17.63379-0.15137,22.83496h-13.09277V40.03076
        c0-0.93848-0.09473-1.6875-0.28516-2.24512c-0.19043-0.55859-0.5459-0.95801-1.06543-1.19922
        c-0.52051-0.24121-1.02148-0.38672-1.50293-0.4375C482.79462,36.09814,482.05927,36.07275,481.06903,36.07275z M481.1452,14.26514
        v11.30371h3.34961c1.77539,0,2.66406-1.85156,2.66406-5.55664c0-2.46094-0.34961-4.03418-1.04688-4.71973
        c-0.69824-0.68457-2.1123-1.02734-4.24316-1.02734H481.1452z M506.24384,63.66455V3.03857h18.45801
        c4.99805,0,8.64453,1.27441,10.94141,3.82422c2.2959,2.5498,3.45703,6.0459,3.48242,10.48535l0.15234,28.31543
        c0,6.06348-1.18652,10.58008-3.55859,13.54785c-2.37305,2.96875-6.19727,4.45312-11.47461,4.45312H506.24384z M519.71552,13.771
        v38.81836c0.07617-0.02441,0.22852-0.03809,0.45703-0.03809c0.15234,0,0.4375,0.01367,0.85645,0.03809
        c0.41895,0.02637,0.75488,0.03809,1.00879,0.03809c2.56152,0,3.84375-1.07812,3.84375-3.23438V19.479
        c0-2.20703-0.34961-3.72266-1.04688-4.54785c-0.69824-0.82422-1.90918-1.23633-3.63477-1.23633
        C520.92059,13.69482,520.42548,13.72021,519.71552,13.771z M561.63641,64.34912c-6.2168,0-10.65039-1.41992-13.30176-4.26172
        s-3.97656-7.23145-3.97656-13.16797v-5.9375h13.24414v5.44238c0,3.90723,1.06543,5.86133,3.19629,5.86133
        c0.98926,0,1.76953-0.38086,2.34082-1.14258c0.57129-0.76074,0.85645-1.7627,0.85645-3.00586
        c0-1.62402-0.22266-2.99414-0.66602-4.11035c-0.44434-1.11621-1.11719-2.17578-2.01758-3.17773s-2.37793-2.3916-4.43359-4.16797
        l-5.6709-4.90918c-4.56641-3.93262-6.84961-8.37305-6.84961-13.32031c0-5.37891,1.37598-9.38086,4.12891-12.00684
        s6.53906-3.93945,11.36035-3.93945c0.12695,0,0.24121,0,0.34277,0c0.10059,0,0.77344,0,2.0166,0s2.8418,0.28516,4.7959,0.85645
        c1.95312,0.57129,3.69141,1.52246,5.21387,2.85449c1.52148,1.33203,2.66406,3.18457,3.4248,5.55664s1.1416,5.27051,1.1416,8.69629
        h-13.58691l-0.07617-3.6543c-0.02539-0.68457-0.31738-1.29395-0.875-1.82617c-0.55859-0.5332-1.17969-0.7998-1.86426-0.7998
        c-0.88867,0-1.64355,0.27344-2.26465,0.81836c-0.62207,0.5459-0.93262,1.25-0.93262,2.1123
        c0,1.72559,1.25586,3.60352,3.76758,5.63281l8.10645,6.5459c1.31934,1.06543,2.4668,2.15039,3.44434,3.25391
        c0.97656,1.10352,1.75098,2.19531,2.32129,3.27246c0.57129,1.0791,1.0332,2.05566,1.38965,2.93066
        c0.35449,0.87598,0.60156,1.82715,0.74219,2.85449c0.13867,1.02734,0.22168,1.80176,0.24707,2.32129
        c0.02441,0.52051,0.03809,1.19922,0.03809,2.03613c0,2.13184-0.13965,4.06641-0.41895,5.80371
        c-0.2793,1.73828-0.77441,3.41309-1.48438,5.02441s-1.64258,2.95605-2.79688,4.03418
        c-1.15527,1.07812-2.65137,1.93457-4.49121,2.56836S564.07196,64.34912,561.63641,64.34912z M10.88665,88.68311H5.46917v9.33008
        H2.41204V74.94971h7.63477c2.59814,0,4.59619,0.5918,5.99561,1.77441c1.39893,1.18262,2.09863,2.9043,2.09863,5.16406
        c0,1.43652-0.3877,2.6875-1.16406,3.75391s-1.85596,1.86426-3.23926,2.3916l5.41748,9.78906v0.19043h-3.26318L10.88665,88.68311z
        M5.46917,86.19678h4.67285c1.50977,0,2.71094-0.3916,3.60352-1.1748c0.89209-0.78223,1.33838-1.83008,1.33838-3.1416
        c0-1.42871-0.4248-2.52344-1.2749-3.28516s-2.07764-1.14746-3.68311-1.1582H5.46917V86.19678z M29.05462,98.32959
        c-2.32324,0-4.21338-0.7627-5.67041-2.28809c-1.45752-1.52637-2.18604-3.56641-2.18604-6.12305v-0.53809
        c0-1.7002,0.32471-3.21777,0.97412-4.55371s1.55762-2.38184,2.72461-3.13672c1.1665-0.75488,2.43115-1.13281,3.79346-1.13281
        c2.22803,0,3.95996,0.73438,5.1958,2.20215c1.23535,1.46777,1.85303,3.56934,1.85303,6.30469v1.21973H24.12835
        c0.04248,1.68945,0.53613,3.05469,1.48145,4.09375c0.94482,1.04102,2.146,1.56055,3.60352,1.56055
        c1.03467,0,1.91113-0.21094,2.62939-0.63379c0.71777-0.42188,1.34619-0.98145,1.88476-1.67871l1.79004,1.39355
        C34.08147,97.22705,31.92718,98.32959,29.05462,98.32959z M28.69036,82.96533c-1.18262,0-2.17529,0.43066-2.97803,1.29102
        c-0.80225,0.86035-1.29883,2.06641-1.48877,3.61914h8.58545v-0.22168c-0.08496-1.48926-0.48584-2.64258-1.2041-3.46094
        C30.88665,83.37451,29.91546,82.96533,28.69036,82.96533z M46.00384,95.93799c1.04541,0,1.9585-0.31641,2.74023-0.9502
        c0.78125-0.63379,1.21436-1.42578,1.29883-2.37598h2.77197c-0.05273,0.98242-0.39062,1.91699-1.01367,2.80371
        s-1.45459,1.59473-2.49463,2.12207c-1.04053,0.52832-2.14111,0.79199-3.30273,0.79199c-2.33398,0-4.18994-0.77832-5.56787-2.33594
        s-2.06689-3.68848-2.06689-6.3916v-0.49121c0-1.66797,0.30615-3.15234,0.91846-4.45117s1.4917-2.30664,2.63721-3.02539
        c1.146-0.71777,2.5-1.07715,4.06299-1.07715c1.92188,0,3.51904,0.57617,4.79199,1.72656
        c1.27197,1.15137,1.95068,2.64551,2.03516,4.4834H50.0429c-0.08447-1.10938-0.50439-2.01953-1.25928-2.73242
        s-1.68701-1.06934-2.7959-1.06934c-1.48877,0-2.64258,0.53613-3.46094,1.60742c-0.81836,1.07227-1.22754,2.62207-1.22754,4.64941
        v0.55371c0,1.97559,0.40625,3.49609,1.21973,4.5625C43.33196,95.40479,44.49358,95.93799,46.00384,95.93799z M55.33343,89.28564
        c0-1.67969,0.33008-3.18945,0.99023-4.53027c0.65967-1.34082,1.57812-2.37598,2.75586-3.10449
        c1.17725-0.72949,2.521-1.09375,4.03125-1.09375c2.33398,0,4.22168,0.80859,5.66309,2.42383s2.16211,3.76465,2.16211,6.44727
        v0.20605c0,1.66797-0.31934,3.16504-0.9585,4.49023s-1.55225,2.35742-2.74023,3.09668s-2.55273,1.1084-4.09473,1.1084
        c-2.32324,0-4.20557-0.80762-5.64697-2.42285c-1.44141-1.61621-2.16211-3.75391-2.16211-6.41504V89.28564z M58.27972,89.63428
        c0,1.90039,0.44092,3.42676,1.32275,4.57715c0.88135,1.15137,2.06152,1.72656,3.54004,1.72656
        c1.48926,0,2.67139-0.58301,3.54834-1.75c0.87646-1.16699,1.31445-2.80078,1.31445-4.90234
        c0-1.87988-0.4458-3.40332-1.33838-4.57031s-2.07764-1.75-3.55615-1.75c-1.44678,0-2.61328,0.5752-3.50049,1.72656
        S58.27972,87.48975,58.27972,89.63428z M73.91351,89.30127c0-2.67188,0.61768-4.79688,1.85352-6.37598
        c1.23535-1.57812,2.87207-2.36816,4.91016-2.36816c2.09082,0,3.72266,0.73926,4.89453,2.21777l0.14258-1.90039h2.67725v16.72656
        c0,2.21777-0.65723,3.96582-1.97217,5.24316s-3.08105,1.91699-5.29834,1.91699c-1.23584,0-2.44482-0.26465-3.62744-0.79199
        c-1.18311-0.52832-2.08594-1.25195-2.7085-2.16992l1.52051-1.75879c1.25635,1.55273,2.79297,2.3291,4.60938,2.3291
        c1.42578,0,2.53662-0.40234,3.33447-1.2041c0.79688-0.80273,1.1958-1.93262,1.1958-3.38965v-1.47363
        c-1.17236,1.35254-2.77197,2.02734-4.7998,2.02734c-2.00635,0-3.62988-0.80762-4.87061-2.42285
        C74.53411,94.29053,73.91351,92.08936,73.91351,89.30127z M76.85979,89.63428c0,1.93164,0.396,3.4502,1.18799,4.55371
        s1.90088,1.65527,3.32666,1.65527c1.84766,0,3.20459-0.83984,4.0708-2.51855v-7.8252
        c-0.89795-1.63672-2.24414-2.45508-4.03906-2.45508c-1.42578,0-2.54004,0.55469-3.34229,1.66309
        C77.26116,85.81592,76.85979,87.4585,76.85979,89.63428z M95.58294,80.87451l0.09473,2.1543
        c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93018v-11.3418
        c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H95.58294z
        M110.72601,76.32861c0-0.47559,0.14502-0.87695,0.43555-1.2041s0.7207-0.49121,1.29102-0.49121s1.00293,0.16406,1.29883,0.49121
        c0.29541,0.32715,0.44385,0.72852,0.44385,1.2041c0,0.47461-0.14844,0.87109-0.44385,1.1875
        c-0.2959,0.31641-0.72852,0.47559-1.29883,0.47559s-1.00049-0.15918-1.29102-0.47559S110.72601,76.80322,110.72601,76.32861z
        M113.89397,98.01318h-2.93018V80.87451h2.93018V98.01318z M128.56195,93.46729c0-0.79199-0.29834-1.40723-0.89503-1.8457
        c-0.59668-0.4375-1.63672-0.81543-3.12061-1.13281c-1.4834-0.31641-2.66113-0.69629-3.53223-1.13965
        c-0.87109-0.44434-1.51514-0.97168-1.93213-1.58398c-0.41748-0.6123-0.62598-1.3418-0.62598-2.18652
        c0-1.4043,0.59424-2.5918,1.78223-3.56348s2.70557-1.45801,4.55371-1.45801c1.94287,0,3.51905,0.50195,4.72852,1.50488
        c1.20898,1.00391,1.81348,2.28711,1.81348,3.84961h-2.94629c0-0.80273-0.34033-1.49414-1.02149-2.0752
        c-0.68115-0.58105-1.53906-0.87109-2.57422-0.87109c-1.06641,0-1.90088,0.23242-2.50244,0.69629
        c-0.60205,0.46484-0.90283,1.07227-0.90283,1.82227c0,0.70703,0.2793,1.24121,0.83936,1.59961
        c0.55957,0.35938,1.5708,0.70215,3.0332,1.0293c1.4624,0.32812,2.64795,0.71875,3.55616,1.17285
        c0.9082,0.4541,1.58154,1,2.01953,1.63867c0.43848,0.63965,0.65771,1.41797,0.65771,2.33691
        c0,1.53125-0.61279,2.75879-1.8374,3.68262c-1.22559,0.92383-2.81446,1.38574-4.76807,1.38574
        c-1.37305,0-2.5874-0.24219-3.64307-0.72852c-1.05615-0.48535-1.88281-1.16406-2.479-2.03516
        c-0.59668-0.87109-0.89502-1.81348-0.89502-2.82715h2.93018c0.05273,0.98145,0.44629,1.76074,1.18018,2.33594
        c0.73389,0.57617,1.70264,0.86328,2.90674,0.86328c1.10889,0,1.99854-0.22363,2.66895-0.67285
        C128.2265,94.81592,128.56195,94.21729,128.56195,93.46729z M135.32562,76.32861c0-0.47559,0.14502-0.87695,0.43555-1.2041
        s0.7207-0.49121,1.29102-0.49121s1.00293,0.16406,1.29883,0.49121c0.29541,0.32715,0.44385,0.72852,0.44385,1.2041
        c0,0.47461-0.14844,0.87109-0.44385,1.1875c-0.2959,0.31641-0.72852,0.47559-1.29883,0.47559s-1.00049-0.15918-1.29102-0.47559
        S135.32562,76.80322,135.32562,76.32861z M138.49359,98.01318h-2.93018V80.87451h2.93018V98.01318z M145.95453,80.87451
        l0.09473,2.1543c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93018
        v-11.3418c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H145.95453
        z M160.3847,89.30127c0-2.67188,0.61768-4.79688,1.85352-6.37598c1.23535-1.57812,2.87207-2.36816,4.91016-2.36816
        c2.09082,0,3.72266,0.73926,4.89453,2.21777l0.14258-1.90039h2.67725v16.72656c0,2.21777-0.65723,3.96582-1.97217,5.24316
        s-3.08105,1.91699-5.29834,1.91699c-1.23584,0-2.44482-0.26465-3.62744-0.79199c-1.18311-0.52832-2.08594-1.25195-2.7085-2.16992
        l1.52051-1.75879c1.25635,1.55273,2.79297,2.3291,4.60938,2.3291c1.42578,0,2.53662-0.40234,3.33447-1.2041
        c0.79688-0.80273,1.1958-1.93262,1.1958-3.38965v-1.47363c-1.17236,1.35254-2.77197,2.02734-4.7998,2.02734
        c-2.00635,0-3.62988-0.80762-4.87061-2.42285C161.00531,94.29053,160.3847,92.08936,160.3847,89.30127z M163.33099,89.63428
        c0,1.93164,0.396,3.4502,1.18799,4.55371s1.90088,1.65527,3.32666,1.65527c1.84766,0,3.20459-0.83984,4.0708-2.51855v-7.8252
        c-0.89795-1.63672-2.24414-2.45508-4.03906-2.45508c-1.42578,0-2.54004,0.55469-3.34229,1.66309
        C163.73236,85.81592,163.33099,87.4585,163.33099,89.63428z M194.42474,98.32959c-2.32324,0-4.21338-0.7627-5.67041-2.28809
        c-1.45752-1.52637-2.18604-3.56641-2.18604-6.12305v-0.53809c0-1.7002,0.32471-3.21777,0.97412-4.55371
        s1.55762-2.38184,2.72461-3.13672c1.1665-0.75488,2.43115-1.13281,3.79346-1.13281c2.22803,0,3.95996,0.73438,5.1958,2.20215
        c1.23535,1.46777,1.85303,3.56934,1.85303,6.30469v1.21973h-11.61084c0.04248,1.68945,0.53613,3.05469,1.48145,4.09375
        c0.94482,1.04102,2.146,1.56055,3.60352,1.56055c1.03467,0,1.91113-0.21094,2.62939-0.63379
        c0.71777-0.42188,1.34619-0.98145,1.88477-1.67871l1.79004,1.39355C199.4516,97.22705,197.2973,98.32959,194.42474,98.32959z
        M194.06049,82.96533c-1.18262,0-2.17529,0.43066-2.97803,1.29102c-0.80225,0.86035-1.29883,2.06641-1.48877,3.61914h8.58545
        v-0.22168c-0.08496-1.48926-0.48584-2.64258-1.2041-3.46094S195.28558,82.96533,194.06049,82.96533z M210.24945,87.13135
        l3.80127-6.25684h3.42139l-5.60742,8.47461l5.78174,8.66406h-3.38965l-3.95996-6.41504l-3.95996,6.41504h-3.40576l5.78174-8.66406
        l-5.60742-8.47461h3.38965L210.24945,87.13135z M227.1347,95.93799c1.04541,0,1.9585-0.31641,2.74023-0.9502
        c0.78125-0.63379,1.21436-1.42578,1.29883-2.37598h2.77197c-0.05273,0.98242-0.39062,1.91699-1.01367,2.80371
        s-1.45459,1.59473-2.49463,2.12207c-1.04053,0.52832-2.14111,0.79199-3.30273,0.79199c-2.33398,0-4.18994-0.77832-5.56787-2.33594
        s-2.06689-3.68848-2.06689-6.3916v-0.49121c0-1.66797,0.30615-3.15234,0.91846-4.45117s1.4917-2.30664,2.63721-3.02539
        c1.146-0.71777,2.5-1.07715,4.06299-1.07715c1.92188,0,3.51904,0.57617,4.79199,1.72656
        c1.27197,1.15137,1.95068,2.64551,2.03516,4.4834h-2.77197c-0.08447-1.10938-0.50439-2.01953-1.25928-2.73242
        s-1.68701-1.06934-2.7959-1.06934c-1.48877,0-2.64258,0.53613-3.46094,1.60742c-0.81836,1.07227-1.22754,2.62207-1.22754,4.64941
        v0.55371c0,1.97559,0.40625,3.49609,1.21973,4.5625C224.46283,95.40479,225.62445,95.93799,227.1347,95.93799z M244.35248,98.32959
        c-2.32324,0-4.21338-0.7627-5.67041-2.28809c-1.45752-1.52637-2.18604-3.56641-2.18604-6.12305v-0.53809
        c0-1.7002,0.32471-3.21777,0.97412-4.55371s1.55762-2.38184,2.72461-3.13672c1.1665-0.75488,2.43115-1.13281,3.79346-1.13281
        c2.22803,0,3.95996,0.73438,5.1958,2.20215c1.23535,1.46777,1.85303,3.56934,1.85303,6.30469v1.21973h-11.61084
        c0.04248,1.68945,0.53613,3.05469,1.48145,4.09375c0.94482,1.04102,2.146,1.56055,3.60352,1.56055
        c1.03467,0,1.91113-0.21094,2.62939-0.63379c0.71777-0.42188,1.34619-0.98145,1.88477-1.67871l1.79004,1.39355
        C249.37933,97.22705,247.22504,98.32959,244.35248,98.32959z M243.98822,82.96533c-1.18262,0-2.17529,0.43066-2.97803,1.29102
        c-0.80225,0.86035-1.29883,2.06641-1.48877,3.61914h8.58545v-0.22168c-0.08496-1.48926-0.48584-2.64258-1.2041-3.46094
        S245.21332,82.96533,243.98822,82.96533z M257.61078,98.01318h-2.93018V73.68311h2.93018V98.01318z M265.48334,98.01318h-2.93018
        V73.68311h2.93018V98.01318z M277.28412,98.32959c-2.32324,0-4.21338-0.7627-5.67041-2.28809
        c-1.45752-1.52637-2.18604-3.56641-2.18604-6.12305v-0.53809c0-1.7002,0.32471-3.21777,0.97412-4.55371
        s1.55762-2.38184,2.72461-3.13672c1.1665-0.75488,2.43115-1.13281,3.79346-1.13281c2.22803,0,3.95996,0.73438,5.1958,2.20215
        c1.23535,1.46777,1.85303,3.56934,1.85303,6.30469v1.21973h-11.61084c0.04248,1.68945,0.53613,3.05469,1.48145,4.09375
        c0.94482,1.04102,2.146,1.56055,3.60352,1.56055c1.03467,0,1.91113-0.21094,2.62939-0.63379
        c0.71777-0.42188,1.34619-0.98145,1.88477-1.67871l1.79004,1.39355C282.31097,97.22705,280.15668,98.32959,277.28412,98.32959z
        M276.91986,82.96533c-1.18262,0-2.17529,0.43066-2.97803,1.29102c-0.80225,0.86035-1.29883,2.06641-1.48877,3.61914h8.58545
        v-0.22168c-0.08496-1.48926-0.48584-2.64258-1.2041-3.46094S278.14496,82.96533,276.91986,82.96533z M290.1308,80.87451
        l0.09473,2.1543c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93018
        v-11.3418c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H290.1308z
        M312.13275,95.93799c1.04541,0,1.9585-0.31641,2.74023-0.9502c0.78125-0.63379,1.21436-1.42578,1.29883-2.37598h2.77197
        c-0.05273,0.98242-0.39062,1.91699-1.01367,2.80371s-1.45459,1.59473-2.49463,2.12207
        c-1.04053,0.52832-2.14111,0.79199-3.30273,0.79199c-2.33398,0-4.18994-0.77832-5.56787-2.33594s-2.06689-3.68848-2.06689-6.3916
        v-0.49121c0-1.66797,0.30615-3.15234,0.91846-4.45117s1.4917-2.30664,2.63721-3.02539c1.146-0.71777,2.5-1.07715,4.06299-1.07715
        c1.92188,0,3.51904,0.57617,4.79199,1.72656c1.27197,1.15137,1.95068,2.64551,2.03516,4.4834h-2.77197
        c-0.08447-1.10938-0.50439-2.01953-1.25928-2.73242s-1.68701-1.06934-2.7959-1.06934c-1.48877,0-2.64258,0.53613-3.46094,1.60742
        c-0.81836,1.07227-1.22754,2.62207-1.22754,4.64941v0.55371c0,1.97559,0.40625,3.49609,1.21973,4.5625
        C309.46088,95.40479,310.6225,95.93799,312.13275,95.93799z M329.35052,98.32959c-2.32324,0-4.21338-0.7627-5.67041-2.28809
        c-1.45752-1.52637-2.18604-3.56641-2.18604-6.12305v-0.53809c0-1.7002,0.32471-3.21777,0.97412-4.55371
        s1.55762-2.38184,2.72461-3.13672c1.1665-0.75488,2.43115-1.13281,3.79346-1.13281c2.22803,0,3.95996,0.73438,5.1958,2.20215
        c1.23535,1.46777,1.85303,3.56934,1.85303,6.30469v1.21973h-11.61084c0.04248,1.68945,0.53613,3.05469,1.48145,4.09375
        c0.94482,1.04102,2.146,1.56055,3.60352,1.56055c1.03467,0,1.91113-0.21094,2.62939-0.63379
        c0.71777-0.42188,1.34619-0.98145,1.88477-1.67871l1.79004,1.39355C334.37738,97.22705,332.22308,98.32959,329.35052,98.32959z
        M328.98627,82.96533c-1.18262,0-2.17529,0.43066-2.97803,1.29102c-0.80225,0.86035-1.29883,2.06641-1.48877,3.61914h8.58545
        v-0.22168c-0.08496-1.48926-0.48584-2.64258-1.2041-3.46094S330.21136,82.96533,328.98627,82.96533z M347.47162,76.32861
        c0-0.47559,0.14502-0.87695,0.43555-1.2041s0.7207-0.49121,1.29102-0.49121s1.00293,0.16406,1.29883,0.49121
        c0.29541,0.32715,0.44385,0.72852,0.44385,1.2041c0,0.47461-0.14844,0.87109-0.44385,1.1875
        c-0.2959,0.31641-0.72852,0.47559-1.29883,0.47559s-1.00049-0.15918-1.29102-0.47559S347.47162,76.80322,347.47162,76.32861z
        M350.63959,98.01318h-2.93018V80.87451h2.93018V98.01318z M358.10052,80.87451l0.09473,2.1543
        c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93018v-11.3418
        c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H358.10052
        z M384.9809,98.01318h-3.04102V74.94971h3.04102V98.01318z M389.30511,89.28564c0-1.67969,0.33008-3.18945,0.99023-4.53027
        c0.65967-1.34082,1.57812-2.37598,2.75586-3.10449c1.17725-0.72949,2.521-1.09375,4.03125-1.09375
        c2.33398,0,4.22168,0.80859,5.66309,2.42383s2.16211,3.76465,2.16211,6.44727v0.20605c0,1.66797-0.31934,3.16504-0.9585,4.49023
        s-1.55225,2.35742-2.74023,3.09668s-2.55273,1.1084-4.09473,1.1084c-2.32324,0-4.20557-0.80762-5.64697-2.42285
        c-1.44141-1.61621-2.16211-3.75391-2.16211-6.41504V89.28564z M392.2514,89.63428c0,1.90039,0.44092,3.42676,1.32275,4.57715
        c0.88135,1.15137,2.06152,1.72656,3.54004,1.72656c1.48926,0,2.67139-0.58301,3.54834-1.75
        c0.87646-1.16699,1.31445-2.80078,1.31445-4.90234c0-1.87988-0.4458-3.40332-1.33838-4.57031s-2.07764-1.75-3.55615-1.75
        c-1.44678,0-2.61328,0.5752-3.50049,1.72656S392.2514,87.48975,392.2514,89.63428z M421.40863,77.43701h-7.41357v20.57617h-3.02539
        V77.43701h-7.39697v-2.4873h17.83594V77.43701z M431.78363,76.32861c0-0.47559,0.14502-0.87695,0.43555-1.2041
        s0.7207-0.49121,1.29102-0.49121s1.00293,0.16406,1.29883,0.49121c0.29541,0.32715,0.44385,0.72852,0.44385,1.2041
        c0,0.47461-0.14844,0.87109-0.44385,1.1875c-0.2959,0.31641-0.72852,0.47559-1.29883,0.47559s-1.00049-0.15918-1.29102-0.47559
        S431.78363,76.80322,431.78363,76.32861z M434.9516,98.01318h-2.93018V80.87451h2.93018V98.01318z M442.41254,80.87451
        l0.09473,2.1543c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93018
        v-11.3418c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H442.41254
        z M460.31195,80.87451l0.09473,2.1543c1.30957-1.64746,3.02002-2.47168,5.13232-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086
        v11.3252h-2.93018v-11.3418c-0.01074-1.23535-0.29297-2.14844-0.84766-2.74023c-0.5542-0.59082-1.41748-0.88672-2.58984-0.88672
        c-0.9502,0-1.78467,0.25293-2.50244,0.75977c-0.71826,0.50781-1.27783,1.17285-1.6792,1.99609v12.21289h-2.93018V80.87451H460.31195
        z M474.66302,89.28564c0-1.67969,0.33008-3.18945,0.99023-4.53027c0.65967-1.34082,1.57812-2.37598,2.75586-3.10449
        c1.17725-0.72949,2.521-1.09375,4.03125-1.09375c2.33398,0,4.22168,0.80859,5.66309,2.42383s2.16211,3.76465,2.16211,6.44727
        v0.20605c0,1.66797-0.31934,3.16504-0.9585,4.49023s-1.55225,2.35742-2.74023,3.09668s-2.55273,1.1084-4.09473,1.1084
        c-2.32324,0-4.20557-0.80762-5.64697-2.42285c-1.44141-1.61621-2.16211-3.75391-2.16211-6.41504V89.28564z M477.60931,89.63428
        c0,1.90039,0.44092,3.42676,1.32275,4.57715c0.88135,1.15137,2.06152,1.72656,3.54004,1.72656
        c1.48926,0,2.67139-0.58301,3.54834-1.75c0.87646-1.16699,1.31445-2.80078,1.31445-4.90234
        c0-1.87988-0.4458-3.40332-1.33838-4.57031s-2.07764-1.75-3.55615-1.75c-1.44678,0-2.61328,0.5752-3.50049,1.72656
        S477.60931,87.48975,477.60931,89.63428z M499.35736,94.0376l4.24512-13.16309h2.99365l-6.146,17.13867h-2.2334l-6.20898-17.13867
        h2.99365L499.35736,94.0376z M519.75873,98.01318c-0.16895-0.33789-0.30664-0.93945-0.41211-1.80566
        c-1.3623,1.41504-2.98828,2.12207-4.87891,2.12207c-1.68945,0-3.0752-0.47754-4.15771-1.43359
        c-1.08252-0.95508-1.62354-2.16699-1.62354-3.63477c0-1.78418,0.67822-3.1709,2.03564-4.1582
        c1.35693-0.9873,3.26514-1.48047,5.72607-1.48047h2.85156v-1.34668c0-1.02441-0.30664-1.83984-0.91895-2.44727
        s-1.51562-0.91113-2.70898-0.91113c-1.04492,0-1.92188,0.26465-2.62891,0.79199c-0.70801,0.52832-1.06152,1.16699-1.06152,1.91699
        h-2.94629c0-0.85547,0.30322-1.68164,0.91064-2.47949c0.60693-0.79688,1.43066-1.42773,2.47119-1.89258
        c1.04004-0.46484,2.18262-0.69727,3.42969-0.69727c1.97363,0,3.52148,0.49414,4.64062,1.48145s1.7002,2.34668,1.74219,4.0791v7.8877
        c0,1.57422,0.20117,2.8252,0.60254,3.75391v0.25391H519.75873z M514.89545,95.77979c0.91895,0,1.79004-0.2373,2.61426-0.71289
        c0.82324-0.47559,1.41992-1.09277,1.79004-1.85352v-3.5166h-2.29688c-3.59082,0-5.38623,1.05078-5.38623,3.15234
        c0,0.91895,0.30615,1.63672,0.91943,2.1543C513.14838,95.521,513.93451,95.77979,514.89545,95.77979z M530.79877,76.72412v4.15039
        h3.2002v2.26465h-3.2002v10.64258c0,0.68652,0.14258,1.20215,0.42773,1.5459s0.77051,0.51562,1.45703,0.51562
        c0.33789,0,0.80273-0.06348,1.39453-0.19043v2.3623c-0.77148,0.20996-1.52051,0.31445-2.25,0.31445
        c-1.30957,0-2.29688-0.39551-2.96191-1.1875s-0.99805-1.91699-0.99805-3.37402V83.13916h-3.12012v-2.26465h3.12012v-4.15039
        H530.79877z M537.43549,76.32861c0-0.47559,0.14551-0.87695,0.43555-1.2041s0.7207-0.49121,1.29102-0.49121
        s1.00293,0.16406,1.29883,0.49121s0.44336,0.72852,0.44336,1.2041c0,0.47461-0.14746,0.87109-0.44336,1.1875
        s-0.72852,0.47559-1.29883,0.47559s-1.00098-0.15918-1.29102-0.47559S537.43549,76.80322,537.43549,76.32861z M540.60345,98.01318
        h-2.93066V80.87451h2.93066V98.01318z M544.51556,89.28564c0-1.67969,0.33008-3.18945,0.99023-4.53027
        c0.65918-1.34082,1.57812-2.37598,2.75586-3.10449c1.17773-0.72949,2.52148-1.09375,4.03125-1.09375
        c2.33398,0,4.22168,0.80859,5.66309,2.42383s2.16211,3.76465,2.16211,6.44727v0.20605c0,1.66797-0.31934,3.16504-0.95801,4.49023
        c-0.63965,1.3252-1.55273,2.35742-2.74023,3.09668c-1.18848,0.73926-2.55371,1.1084-4.09473,1.1084
        c-2.32324,0-4.20605-0.80762-5.64746-2.42285c-1.44141-1.61621-2.16211-3.75391-2.16211-6.41504V89.28564z M547.46185,89.63428
        c0,1.90039,0.44043,3.42676,1.32227,4.57715c0.88184,1.15137,2.0625,1.72656,3.54102,1.72656c1.48828,0,2.6709-0.58301,3.54785-1.75
        c0.87598-1.16699,1.31445-2.80078,1.31445-4.90234c0-1.87988-0.44629-3.40332-1.33789-4.57031
        c-0.89258-1.16699-2.07812-1.75-3.55664-1.75c-1.44629,0-2.61328,0.5752-3.50098,1.72656
        C547.90521,85.84326,547.46185,87.48975,547.46185,89.63428z M566.56439,80.87451l0.0957,2.1543
        c1.30859-1.64746,3.01953-2.47168,5.13184-2.47168c3.62207,0,5.44922,2.04395,5.48047,6.13086v11.3252h-2.93066v-11.3418
        c-0.00977-1.23535-0.29297-2.14844-0.84668-2.74023c-0.55469-0.59082-1.41797-0.88672-2.58984-0.88672
        c-0.95117,0-1.78516,0.25293-2.50293,0.75977c-0.71875,0.50781-1.27832,1.17285-1.67871,1.99609v12.21289h-2.93066V80.87451
        H566.56439z"/>
      </svg>
    `}
  />
);

export default IOTGALogo;
