import SVGInline from 'react-svg-inline';

const AsanaIcon = () => (
  <SVGInline
    component="div"
    className="asana-icon"
    classSuffix=""
    accessibilityLabel="Asana"
    fill="#121415"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 477.92999 95.253" style="enable-background:new 0 0 477.92999 95.253;" xml:space="preserve">
        <path d="M181.748,72.679c0.28799,3.275,2.91,7.445,7.453,7.445h2.658c1.03,0,1.87399-0.844,1.87399-1.874V19.839h-0.011
          c-0.054-0.984-0.868-1.773-1.86301-1.773h-8.23601c-0.996,0-1.80901,0.789-1.86501,1.773h-0.00999v4.746
          c-5.04601-6.215-13.002-8.795-20.981-8.795c-18.13499,0-32.83701,14.701-32.83701,32.836
          c0,18.136,14.701,32.83801,32.83701,32.83801v0.001c7.979,0,16.673-3.095,20.98-8.794L181.748,72.679z M160.8,69.972
          c-11.534,0-20.884-9.556-20.884-21.346c0-11.787,9.351-21.344,20.884-21.344c11.534,0,20.883,9.557,20.883,21.344
          C181.683,60.416,172.33299,69.972,160.8,69.972z M315.935,72.679c0.28799,3.275,2.91,7.445,7.453,7.445h2.65799
          c1.03,0,1.87399-0.844,1.87399-1.874V19.839H327.909c-0.05399-0.984-0.86801-1.773-1.86301-1.773H317.81
          c-0.996,0-1.81,0.789-1.86499,1.773H315.935v4.746c-5.047-6.215-13.00299-8.795-20.98099-8.795
          c-18.13501,0-32.836,14.701-32.836,32.836c0,18.136,14.70099,32.83801,32.836,32.83801v0.001c7.979,0,16.673-3.095,20.98001-8.794
          L315.935,72.679z M294.98599,69.972c-11.53299,0-20.884-9.556-20.884-21.346c0-11.787,9.35101-21.344,20.884-21.344
          c11.534,0,20.883,9.557,20.883,21.344C315.86899,60.416,306.51999,69.972,294.98599,69.972z M465.94299,72.679
          c0.28799,3.275,2.91,7.445,7.453,7.445h2.65799c1.03,0,1.875-0.844,1.875-1.874V19.839h-0.01199
          c-0.05399-0.984-0.867-1.773-1.86301-1.773h-8.23599c-0.996,0-1.81,0.789-1.86401,1.773h-0.01099v4.746
          c-5.04599-6.215-13.00201-8.795-20.98001-8.795c-18.13599,0-32.83701,14.701-32.83701,32.836
          c0,18.136,14.70099,32.83801,32.83701,32.83801v0.001c7.978,0,16.673-3.095,20.979-8.794L465.94299,72.679z M444.995,69.972
          c-11.535,0-20.88501-9.556-20.88501-21.346c0-11.787,9.35001-21.344,20.88501-21.344c11.53299,0,20.88199,9.557,20.88199,21.344
          C465.87701,60.416,456.52802,69.972,444.995,69.972z M401.71399,74.687l-0.004-30.225l0,0c0-17.038-10.74899-28.532-27.88901-28.532
          c-8.18301,0-15.66901,4.69-17.259,8.795c-0.51199-3.178-2.19901-6.518-7.43201-6.518h-2.66699
          c-1.03101,0-1.87399,0.845-1.87399,1.875v52.743c0,0.001,0,0.005,0,0.008v5.563v0.103h0.01099
          c0.05399,0.982,0.86801,1.771,1.86401,1.771h5.578h2.65799c0.12601,0,0.25-0.015,0.36899-0.038
          c0.05499-0.011,0.10501-0.032,0.15799-0.047c0.061-0.019,0.125-0.034,0.18399-0.058c0.073-0.03,0.14001-0.071,0.20801-0.109
          c0.03299-0.021,0.06799-0.035,0.10101-0.056c0.082-0.053,0.15701-0.117,0.23001-0.182c0.01199-0.012,0.026-0.02,0.03799-0.031
          c0.082-0.079,0.15601-0.166,0.22299-0.257c0.00101-0.001,0.00101-0.001,0.00101-0.001c0.20599-0.283,0.33099-0.624,0.35199-0.992
          h0.01099v-0.103v-3.707v-0.974V43.994c0-9.15,7.41901-16.569,16.57001-16.569s16.57001,7.419,16.57001,16.569l0.008,28.841
          l0.00201-0.01c0,0.025,0.004,0.052,0.004,0.079v5.492v0.103h0.01099c0.05499,0.982,0.86801,1.771,1.86401,1.771h5.57501H399.84l0,0
          c0.12701,0,0.25-0.015,0.36899-0.038c0.04901-0.009,0.09399-0.028,0.142-0.042c0.06699-0.02,0.13699-0.036,0.20099-0.062
          c0.06699-0.028,0.129-0.066,0.19199-0.103c0.039-0.022,0.07999-0.038,0.117-0.062c0.07599-0.05,0.146-0.11,0.21399-0.17
          c0.01801-0.016,0.03699-0.028,0.05399-0.044c0.078-0.075,0.147-0.156,0.211-0.242c0.005-0.006,0.01001-0.011,0.01501-0.018
          c0.203-0.281,0.327-0.619,0.349-0.985c0-0.003,0.00101-0.005,0.00101-0.005h0.01099v-0.103v-3.707L401.71399,74.687z
            M213.411,64.313c5.498,3.81,11.5,5.659,17.26801,5.659c5.494,0,11.174-2.849,11.174-7.806c0-6.619-12.377-7.649-20.153-10.292
          c-7.778-2.643-14.47701-8.106-14.47701-16.955c0-13.539,12.064-19.13,23.323-19.13c7.134,0,14.494,2.352,19.267,5.722
          c1.644,1.245,0.64301,2.675,0.64301,2.675l-4.556,6.506c-0.51199,0.732-1.407,1.366-2.692,0.573s-5.79201-3.983-12.661-3.983
          c-6.87,0-11.008,3.173-11.008,7.105c0,4.715,5.37801,6.198,11.67799,7.806c10.978,2.959,22.953,6.516,22.953,19.974
          c0,11.929-11.158,19.301-23.489,19.301c-9.34599,0-17.302-2.664-23.97501-7.561c-1.39101-1.391-0.42-2.683-0.42-2.683l4.533-6.473
          C211.739,63.539,212.90199,63.962,213.411,64.313z"/>
        <g>
          <defs>
            <path id="asana-icon-path-1" d="M76.392,22.412c0,12.379-10.034,22.415-22.412,22.415S31.568,34.791,31.568,22.412
              C31.568,10.036,41.602,0,53.98,0S76.392,10.036,76.392,22.412z M24.866,50.431c-12.378,0-22.412,10.032-22.412,22.41
              s10.034,22.412,22.412,22.412s22.413-10.034,22.413-22.412C47.279,60.462,37.244,50.431,24.866,50.431z M83.094,50.428
              c-12.378,0-22.412,10.034-22.412,22.413c0,12.378,10.034,22.412,22.412,22.412s22.412-10.034,22.412-22.412
              C105.506,60.462,95.472,50.428,83.094,50.428z"/>
          </defs>
          <use xlink:href="#asana-icon-path-1" style="overflow:visible;"/>
          <clipPath id="asana-icon-path-2">
            <use xlink:href="#asana-icon-path-1" style="overflow:visible;"/>
          </clipPath>
          <path clip-path="url(#asana-icon-path-2)" d="M53.98-1c31.163,0,56.422,25.265,56.422,56.428c0,31.16-25.26,56.421-56.422,56.421
            S-2.443,86.588-2.443,55.428C-2.443,24.265,22.819-1,53.98-1z"/>
        </g>
      </svg>
      <span class="tooltip">Asana</span>
    `}
  />
);

export default AsanaIcon;
