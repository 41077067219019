import SVGInline from 'react-svg-inline';

const ContentfulIcon = () => (
  <SVGInline
    component="div"
    className="contentful-icon"
    classSuffix=""
    accessibilityLabel="Contentful"
    fill="#fff"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 700 149" style="enable-background:new 0 0 700 149;" xml:space="preserve">
        <g>
          <defs>
            <rect id="contentful-icon-rect-1" width="700" height="149"/>
          </defs>
          <clipPath id="contentful-icon-path-2">
            <use xlink:href="#contentful-icon-rect-1"  style="overflow:visible;"/>
          </clipPath>
          <path fill="#121415" clip-path="url(#contentful-icon-path-2)" d="M196.87589,88.7938c4.29001-3.376,4.95001-1.46301,8.69101,2.364c1.87,1.913,6.70999,5.177,3.62999,7.991
            c-13.97099,12.269-35.09299,10.69199-47.084-3.60201c-5.50099-6.528-8.36099-14.85699-8.03099-23.411
            c0.22-9.117,4.181-17.671,10.78099-23.636c6.27101-5.29,14.30101-7.991,22.442-7.766c4.071,0,8.03101,0.788,11.77101,2.363
            c2.53,1.013,4.95,2.252,7.151,3.94c4.39999,3.376,2.42,5.515-1.10001,9.004l-2.31,2.364
            c-1.761,1.801-2.97101,3.038-5.28101,1.46301c-4.401-3.37701-10.121-4.61501-15.511-3.37701
            c-17.272,4.61501-16.282,36.017,4.84,36.017C190.6059,92.2828,194.01588,91.0448,196.87589,88.7938 M676.62585,106.01379
            c-2.85999,0-5.28101-2.36299-5.28101-5.40199v-69.558c0-2.926,2.31-5.402,5.28101-5.402h5.61005
            c2.85999,0,5.28094,2.363,5.28094,5.402v69.67c0,2.927-2.31097,5.404-5.28094,5.404L676.62585,106.01379z M647.0329,98.1358
            c-5.28003,5.853-12.87103,9.117-20.68103,9.004c-17.60199,0-25.96198-14.857-25.96198-31.515v-28.25
            c0-2.927,2.30896-5.403,5.27997-5.403h5.60999c2.86102,0,5.28101,2.364,5.28101,5.403v28.138
            c-0.99097,8.104,4.62,15.419,12.42999,16.433c0.66003,0.112,1.32104,0.112,2.091,0.112
            c8.80103-0.225,15.73199-7.766,15.51105-16.658v-0.675v-10.13v-1.126v-16.094c0-2.927,2.31-5.403,5.27997-5.403h5.61102
            c2.85999,0,5.28101,2.364,5.28101,5.403v53.463c0,2.925-2.31104,5.29-5.17102,5.29h-4.73102
            c-2.85999-0.11401-5.06-2.365-5.16998-5.29v-2.702H647.0329z M579.48688,42.1968h11.00098c2.86102,0,5.17102,2.47601,5.17102,5.403
            v3.714c0,2.92599-2.31,5.402-5.17102,5.402h-11.00098v44.008c0,2.927-2.30902,5.404-5.28003,5.404h-5.60999
            c-2.86102,0-5.27997-2.365-5.27997-5.404v-60.778c-1.10101-12.718,8.02997-23.86099,20.35095-24.987h0.22003h8.58099
            c2.85999,0,5.16998,2.476,5.16998,5.403v3.376c0,2.927-2.31,5.403-5.16998,5.403h-4.18103c-6.26996,0-8.90997,4.952-8.90997,10.918
            L579.48688,42.1968z M540.32385,42.1968h11.992c2.85999,0,5.16901,2.364,5.16901,5.29v3.601c0,2.927-2.30902,5.291-5.16901,5.291
            h-11.992v26.9c0,5.178,2.42004,8.217,7.591,8.217h2.41998c2.86005,0,5.17102,2.36299,5.17102,5.289v3.60199
            c0,2.926-2.31097,5.29001-5.17102,5.29001h-7.26099c-12.97998-0.78801-18.81097-9.455-18.81097-22.398v-52.112
            c0-2.927,2.31-5.29,5.17096-5.29h5.5c2.86005,0,5.17102,2.363,5.17102,5.29L540.32385,42.1968z M366.72989,42.1968h11.991
            c2.85999,0,5.17099,2.364,5.17099,5.29v3.601c0,2.927-2.311,5.291-5.17099,5.291h-11.66101v26.9c0,5.178,2.42099,8.217,7.591,8.217
            h2.41901c2.86099,0,5.17099,2.36299,5.17099,5.289v3.60199c0,2.926-2.31,5.29001-5.17099,5.29001h-3.629
            c-14.19202,0-22.332-6.19-22.332-22.51v-52c0-2.927,2.31-5.29,5.16998-5.29h5.5c2.86099,0,5.17102,2.363,5.17102,5.29v11.03
            H366.72989z M296.32388,49.9628c5.17099-5.627,12.431-8.892,20.022-9.004c17.491,0,26.73199,14.744,26.73199,31.515v28.25
            c0,2.927-2.31,5.404-5.28101,5.404h-5.60999c-2.86002,0-5.28-2.365-5.28-5.404v-28.137c0.98999-8.104-4.621-15.42-12.431-16.434
            c-0.66-0.112-1.43002-0.112-2.091-0.112c-8.80002,0.225-15.73102,7.766-15.401,16.658v0.563v27.35
            c0,2.927-2.31,5.28999-5.17001,5.28999h-5.50101c-2.85999,0-5.16998-2.36299-5.16998-5.28999v-53.35
            c0-2.926,2.31-5.29,5.16998-5.29h4.73102c2.85999,0.113,5.06,2.364,5.16998,5.29L296.32388,49.9628z M470.02789,49.9628
            c5.17099-5.627,12.431-8.892,20.02197-9.004c17.492,0,26.73199,14.744,26.73199,31.515v28.25c0,2.927-2.31,5.404-5.27997,5.404
            h-5.61002c-2.86099,0-5.28101-2.365-5.28101-5.404v-28.137c0.99002-8.104-4.621-15.42-12.431-16.434
            c-0.66-0.112-1.42999-0.112-2.09-0.112c-8.80099,0.225-15.73099,7.766-15.401,16.658v0.563v27.35
            c0,2.927-2.311,5.28999-5.17099,5.28999h-5.61099c-2.86002,0-5.17001-2.36299-5.17001-5.28999v-53.35
            c0-2.926,2.31-5.29,5.17001-5.29h4.73099c2.86002,0.113,5.06,2.364,5.17099,5.29v2.814L470.02789,49.9628z M242.96989,40.7338
            c-17.382-0.563-31.903,13.281-32.453,31.064v2.364c0,18.459,14.631,33.31599,32.563,33.31599
            c17.931,0,32.56198-14.97,32.56198-33.31599c0.54999-17.896-13.091-32.866-30.58199-33.428H242.96989z M400.94186,80.1278
            c1.98102,8.215,9.681,13.731,17.93201,12.605c4.07001-0.112,8.14099-1.013,11.772-2.701l1.759-0.901c1.98099-1.125,3.741-1.8,5.5,0
            l2.86099,2.927c0.88101,0.901,1.76001,1.91299,2.53,2.814c2.64102,3.377,0.771,4.27701-2.19998,6.302
            c-6.71002,4.50301-14.521,6.867-22.552,6.867c-20.68201,0-34.43301-12.831-34.43301-34.217
            c-0.76999-17.671,12.54102-32.753,29.923-33.53999h3.51999c20.022,0,31.57303,12.043,31.90302,32.64
            c0,5.627,0.32999,7.42799-5.39001,7.42799L400.94186,80.1278z M243.5199,55.7028c9.461,0.225,16.94098,8.217,16.72098,17.896v0.45
            c0.77002,9.23-5.82999,17.447-14.96098,18.34699c-9.021,0.787-17.05099-5.966-17.93201-15.308
            c-0.11-1.01199-0.11-2.02499,0-3.03899c-0.76999-9.341,6.05101-17.55801,15.181-18.233h1.211L243.5199,55.7028z M401.71289,66.1708
            h32.12198c-1.319-7.54099-8.25-12.606-15.621-11.48C410.84387,54.35281,404.13287,59.0798,401.71289,66.1708"/>
          <path clip-path="url(#contentful-icon-path-2)" stroke="#121415" stroke-width="6.676" stroke-miterlimit="10" d="M53.2036,102.1879h-0.55c-7.04-7.20399-10.891-17.10799-10.671-27.35099
            c0-10.242,4.071-20.147,11.111-27.35001c6.271-5.515,6.931-15.195,1.54-21.60999c-5.39-6.416-14.851-7.091-21.121-1.576
            c-0.55,0.45-0.991,1.013-1.54,1.576C5.5696,53.1149,5.5696,97.1229,32.0816,124.2479c5.941,5.965,15.512,5.853,21.342-0.225
            C59.2546,117.9449,59.1446,108.1529,53.2036,102.1879z"/>
          <path clip-path="url(#contentful-icon-path-2)" stroke="#121415" stroke-width="6.676" stroke-miterlimit="10" d="M52.3237,47.7118l1.1-0.562c14.851-15.195,38.943-15.195,53.795,0c5.39,6.415,14.85101,7.09,21.12099,1.575
            c6.27101-5.515,6.931-15.195,1.541-21.61c-0.44099-0.563-0.991-1.013-1.541-1.576c-26.51199-27.125-69.52499-27.125-96.03699,0
            c-5.171,6.641-4.071,16.208,2.419,21.498c5.391,4.389,12.982,4.389,18.482,0.113L52.3237,47.7118z"/>
          <path clip-path="url(#contentful-icon-path-2)" stroke="#121415" stroke-width="6.676" stroke-miterlimit="10" d="M107.2183,102.1879c-14.85101,14.969-38.613,14.969-53.464,0c-5.391-6.41599-14.851-7.091-21.122-1.57599
            c-6.27,5.515-6.931,15.19399-1.54,21.60999c0.44,0.563,0.99,1.013,1.54,1.575c26.512,27.126,69.52499,27.126,96.03799,0
            c5.83-5.964,5.83-15.644,0-21.609c-5.82999-5.966-13.31099-7.991-18.92199-2.02599L107.2183,102.1879z"/>
          <path clip-path="url(#contentful-icon-path-2)" stroke="#121415" stroke-width="6.676" stroke-miterlimit="10" d="M53.2036,47.2616c-5.72,5.853-14.961,5.965-20.792,0.11301l-0.109-0.11301
            c-5.941-5.853-6.051-15.645-0.221-21.61c5.721-6.07799,15.292-6.18999,21.122-0.225c5.831,5.965,6.051,15.645,0.22,21.61
            C53.3136,47.1496,53.3136,47.1496,53.2036,47.2616z"/>
          <path clip-path="url(#contentful-icon-path-2)" stroke="#121415" stroke-width="6.676" stroke-miterlimit="10" d="M52.5439,122.8969c-5.391,5.51601-13.971,5.51601-19.362,0c-5.39-5.515-5.39-14.29399,0-19.80899
            c5.501-5.515,14.301-5.62801,20.022-0.33701C58.3749,108.60291,58.1539,117.4949,52.5439,122.8969z"/>
        </g>
      </svg>
      <span class="tooltip">Contentful</span>
    `}
  />
);

export default ContentfulIcon;
