import SVGInline from 'react-svg-inline';

const FWMIcon = () => (
  <SVGInline
    className="fwm-icon"
    classSuffix=""
    accessibilityLabel="Forwebmotion icon links to homepage"
    fill="#40AFE9"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 178 69" style="enable-background:new 0 0 178 69;" xml:space="preserve">
        <path d="M6.16823,68.16666L6.16667,38.875H0.70833V27.66667h5.4599L6.16667,18.7303
        c0.00207-18.61893,17.66491-21.67664,31.5-14.48691l-4.5,12.03937c-7.55755-3.5075-13.5-2.68455-13.5,4.50735l0.00196,6.87656
        l13.4996,0.00156L33.16667,38.875H19.66649l0.00018,29.29166H6.16823z M105.16666,27.66667l-13.49844,0.00156l-7.00156,22.50156
        l-8.99844-22.50156h-9l-9,22.49844l-6.50156-22.49844l-13.5-0.00156L53.16823,68.1682l8.99844,0.00139l9-22.50136l9.00156,22.49844
        l9,0.00016L105.16666,27.66667z M163.66823,68.16666h13.49844c0,0,0-23.90637,0-27
        c-0.00046-15.51801-17.48468-20.14676-26.77283-10.90796c-0.05792,0.05762-1.25458,1.10883-2.40131,2.38666
        c-5.01819-8.44577-17.55838-9.66629-24.82587-2.19589v-2.78281l-13.5,0.00156v17.48031v23.01812h13.5V38.87523
        c0,0,2.54071-4.56274,7.54169-4.56274c6.5,0,5.98956,6.85504,5.98956,6.85504l-0.02969,26.99914h13.5
        c0,0-0.00156-23.90481-0.00156-26.99844c0-0.80518-0.05093-1.54707-0.14114-2.29323
        c0.61237-0.94318,3.40811-4.56252,7.71408-4.56252c6.5,0,5.98955,6.85504,5.98955,6.85504L163.66823,68.16666z"/>
      </svg>
    `}
  />
);

export default FWMIcon;
