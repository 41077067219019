import SVGInline from 'react-svg-inline';

const LinkedInIcon = () => (
  <SVGInline
    className="linkedin-icon"
    classSuffix=""
    accessibilityLabel="LinkedIn profile for Dave Green"
    fill="#40AFE9"
    svg={`
      <svg viewBox="0 0 128 128">
        <path d="M116,3H12a8.91,8.91,0,0,0-9,8.8V116.22A8.91,8.91,0,0,0,12,125H116a8.93,8.93,0,0,0,9-8.81V11.77A8.93,8.93,0,0,0,116,3ZM39.17,107H21.06V48.73H39.17Zm-9-66.21a10.5,10.5,0,1,1,10.49-10.5A10.5,10.5,0,0,1,30.12,40.77ZM107,107H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87,7.36-10.87,15V107H50.53V48.73H67.89v8h.24c2.42-4.58,8.32-9.41,17.13-9.41C103.6,47.28,107,59.35,107,75Z"/>
      </svg>
    `}
  />
);

export default LinkedInIcon;
