import SVGInline from 'react-svg-inline';

const SagePayIcon = () => (
  <SVGInline
    component="div"
    className="sagepay-icon"
    classSuffix=""
    accessibilityLabel="Sage Pay REST API"
    fill="#121415"
    svg={`
      <svg x="0px" y="0px" viewBox="0 0 258.33334 60" style="enable-background:new 0 0 258.33334 60;" xml:space="preserve">
        <style type="text/css">
          .sagepay1{fill-rule:evenodd;clip-rule:evenodd;}
        </style>
        <path class="sagepay1" d="M135.52777,19.22012c0-6.02454-4.45203-17.26647-18.84951-17.31214
        c-6.24482-0.01963-10.95068,2.8745-13.11504,4.78029V2.4138l-9.63-0.005v4.27948c-2.35953-2.13995-5.22674-4.07131-8.11176-4.52715
        c-3.06715-0.48576-6.13435-0.3415-9.92737,0.85942c-3.15438,0.99817-5.16517,2.4721-7.11223,4.77847
        C64.76195,3.0758,58.41026,2.13214,53.34766,1.93813c-5.76363-0.22028-11.575,0.5261-16.10328,3.99913
        c-4.36484-2.34288-9.88062-4.18313-16.00305-4.14662C15.85794,1.82285,10.70035,3.09679,7.04655,6.41003
        c-1.31755,1.19493-1.9092,2.14383-2.5618,3.83869c-1.29334,3.35709-0.75531,7.23434,0.6864,9.25765
        c2.68915,3.77413,5.70039,5.24001,11.10977,5.87822c4.75154,0.56107,8.07894,0.56248,11.59441,1.24224
        c1.66106,0.32139,3.18519,1.61151,3.18519,3.52482c0,2.23148-2.97314,5.8892-10.15063,5.39844
        c-5.98004-0.40769-8.23849-1.29292-13.86333-5.13366l-6.35052,5.38975c0,0,1.08358,0.90804,1.82201,1.4175
        c0.71104,0.49035,1.4506,0.95231,2.21325,1.38328H4.75c6.40437,3.62804,14.50953,5.14507,21.84634,3.91246
        c3.59905-0.60675,7.01341-1.87316,9.95093-3.87866c2.37481,1.7051,5.51255,4.62873,13.10821,4.27036
        c4.78099-0.22598,8.33021-0.04567,11.65009-4.05214v4.0037h9.30033l-0.00248-4.0037c0,0,1.46682,1.24495,2.49786,1.8941
        c5.90608,3.7207,15.3,2.31324,20.85505-2.12466l0.01276,5.17975c-0.15885,3.19566-2.9544,5.38334-5.89327,6.61507
        c-5.07546,2.12648-9.69914,0.22051-13.82818-2.8364l-7.04739,4.9469c1.90233,2.0005,3.64169,3.16878,6.35234,4.47259
        c7.62193,3.66319,16.53878,2.41412,22.69137-1.41703c4.0569-2.52735,5.62648-5.62578,6.60618-10.38919
        c0.52064-2.53277,0.57201-4.38676,0.65144-6.60272l0,0c0.00253-0.06437,0.00253-0.12234,0.00506-0.17895
        c0.08213,0.06026,0.16409,0.12004,0.24374,0.17895c1.96193,1.43761,3.69811,2.50314,6.35234,3.35091
        c4.28542,1.36683,9.16064,1.42891,14.60266-0.06208c2.53259-0.69529,4.76752-1.80556,6.76391-3.28884h0.00388
        c1.0797-0.80669,2.09021-1.72019,3.0416-2.73458l-6.07794-4.54244c-2.05939,1.76358-5.12407,3.54263-8.61691,4.006
        c-4.0341,0.53644-8.60921-0.22869-11.08678-3.04822c-1.33849-1.52343-1.80532-2.00274-1.80532-5.86317l28.59998,0.03333V19.22012z
        M74.62975,22.02413c0.0769-2.21872,0.27708-4.59175,0.50264-6.92234c0.11023-1.13696,1.41815-2.51043,3.25704-3.56315
        c1.66952-0.9553,3.77754-1.64624,5.82413-1.65765c1.55653-0.0087,3.15439,0.3004,4.56754,0.87218
        c2.46433,0.99499,4.37489,2.78597,4.57259,5.07682c0.18213,2.13378,0.39217,4.15185,0.48711,6.08569
        c0.118,2.41618,0.06161,4.70198-0.4385,6.91706c-0.32046,1.41114-0.98723,2.6086-2.36457,3.75491
        c-1.55653,1.29334-3.9672,2.44246-6.82417,2.27444c-3.99824-0.23468-7.57307-1.89826-8.62968-4.2238
        C74.69637,28.67982,74.50919,25.54618,74.62975,22.02413z M57.65429,34.94974c-1.99321,0.65466-5.18045,0.98835-8.57335,0.55878
        c-2.82359-0.35702-5.51185-0.56701-6.61207-3.2304c-0.59557-1.44307-0.47047-2.76792,0.4565-4.001
        c1.45612-1.93655,4.82708-2.31226,6.84881-2.33465h11.07555C61.14466,28.97153,62.06841,33.4998,57.65429,34.94974z
        M24.75816,18.41049c-4.44062-0.24926-8.52585-0.33944-10.74504-2.2039c-1.27711-1.07371-1.02374-4.16284,0.7663-5.28222
        c2.57527-1.60992,5.69151-1.55606,7.48725-1.45836c3.6358,0.19723,7.82304,1.4456,11.11916,3.75126l3.85855-3.75126
        c0,0,4.0729,3.91811,4.0729,3.75126c4.74967-3.47302,6.42559-3.4819,10.84957-3.47783
        c6.04964,0.00482,7.78648,1.23878,8.67582,2.6344c0.56243,0.88111,0.00706,6.25898,0.00706,6.25898
        s-5.34066,0.17231-9.19262-0.03039c-3.06075-0.16091-4.77272-0.17325-7.85155,0.35426
        c-2.75216,0.47136-4.64378,1.3162-6.56116,2.98455c0,0-1.64077-1.20433-3.42828-2.06305
        C30.56541,18.3169,28.23941,18.6059,24.75816,18.41049z M107.15083,19.1076c0-2.42348,0.60535-4.36213,1.60033-5.86957
        c1.9875-3.00666,5.53196-4.29523,8.94014-4.28812c3.35456,0.00753,6.81136,1.27253,8.86823,4.02816
        c1.16165,1.55447,1.87727,3.58419,1.87727,6.12954H107.15083z"/>
        <path class="sagepay1" d="M165.34053,5.02057c-5.05606,0-8.70369,1.6159-10.94203,4.84761
        c-2.23836,3.23175-3.35774,7.39641-3.35774,12.49375c0,4.83508,1.15865,8.93425,3.47601,12.29699
        c2.31731,3.36321,5.92525,5.0446,10.82376,5.0446c4.37166,0,7.68971-1.72108,9.95453-5.16283
        c2.2648-3.44222,3.39748-7.50164,3.39748-12.17876c0-4.72938-1.11938-8.80204-3.35822-12.21775
        C173.09596,6.72843,169.76469,5.02057,165.34053,5.02057z M146.06323,2.01845h4.58217v7.74245
        c1.21162-2.79162,3.12082-4.97748,5.72801-6.55752c2.60725-1.58004,5.5961-2.37004,8.96712-2.37004
        c5.95171,0,10.49414,2.03724,13.62819,6.11172c3.13405,4.07449,4.70087,9.21338,4.70087,15.41688
        c0,6.15076-1.58005,11.27666-4.74055,15.37765c-3.1601,4.10048-7.68976,6.15123-13.5885,6.15123
        c-3.21306,0-6.10973-0.77018-8.69046-2.31002c-2.58121-1.54036-4.39816-3.7198-5.45135-6.53886l-0.15796,22.75373h-4.97752V2.01845
        z"/>
        <path class="sagepay1" d="M214.30521,20.50546c-0.2639,0.4198-0.71127,0.7606-1.34312,1.02262
        c-0.63226,0.2623-2.34377,0.64237-5.13544,1.14066c-2.79166,0.49852-6.29413,1.11481-10.50784,1.84983
        c-4.21327,1.31068-6.32018,3.69788-6.32018,7.15968c0,2.41319,0.89528,4.3539,2.6862,5.82207
        c1.79051,1.46864,3.92386,2.2032,6.39961,2.2032c3.94984,0,7.30759-1.1673,10.07274-3.50154
        c2.76521-2.33423,4.14803-5.4162,4.14803-9.24558V20.50546z M187.75912,14.50103c0-4.58217,1.51477-8.00564,4.54337-10.27045
        c3.02812-2.26486,7.01817-3.39724,11.96919-3.39724c4.37123,0,7.9659,0.94796,10.78407,2.84416
        c2.81766,1.89616,4.2265,5.31967,4.2265,10.27045v21.6473c0,2.00143,0.97424,3.00212,2.92313,3.00212
        c0.52686,0,1.05319-0.10499,1.58005-0.31592v4.18729c-1.0007,0.21046-2.0804,0.31588-3.23906,0.31588
        c-2.37027,0-3.93709-0.56607-4.70085-1.69869c-0.76378-1.13219-1.14543-2.93593-1.14543-5.41167
        c-2.00185,2.8688-4.06902,4.9556-6.2019,6.25987c-2.13336,1.30428-5.06924,1.9567-8.8091,1.9567
        c-4.00282,0-7.2816-0.99892-9.83635-2.99572c-2.55423-1.99732-3.83157-4.967-3.83157-8.90956
        c0-3.57461,0.97466-6.21654,2.92311-7.92392c1.9489-1.70833,4.18724-2.82547,6.71548-3.35115
        c2.52824-0.52592,5.66187-1.0121,9.40173-1.45859c3.73985-0.44649,6.21513-0.90621,7.42628-1.3794
        c1.21162-0.47342,1.81743-1.97151,1.81743-4.4947c0-3.15345-0.93497-5.33477-2.8049-6.54387
        c-1.86992-1.20886-4.4904-1.81332-7.8609-1.81332c-3.26555,0-5.89879,0.77677-7.90022,2.33059
        c-2.00185,1.55377-3.00255,3.93704-3.00255,7.14986H187.75912z"/>
        <path class="sagepay1" d="M221.38135,2.01845l5.35229-0.02351l13.38675,34.67542l12.54301-34.65326l4.98116,0.00136l-17.69948,47.00644
        c-1.41756,3.44586-2.80855,5.76864-4.17313,6.96979c-1.36501,1.20063-3.54356,1.80098-6.53564,1.80098
        c-1.83794,0-3.09796-0.10546-3.78001-0.31639v-4.18677c1.31204,0.21046,2.44012,0.31593,3.38467,0.31593
        c2.30865,0,3.88277-0.65377,4.72278-1.96123c0.83955-1.30703,1.62662-2.77161,2.3607-4.39228l1.73207-4.62822L221.38135,2.01845z"/>
      </svg>
      <span class="tooltip">Sage Pay REST API</span>
    `}
  />
);

export default SagePayIcon;
