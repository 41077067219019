import SVGInline from 'react-svg-inline';

const NginxIcon = () => (
  <SVGInline
    component="div"
    className="nginx-icon"
    classSuffix=""
    accessibilityLabel="Nginx"
    fill="#121415"
    svg={`
      <svg viewBox="0 0 128 128">
        <path d="M24.5 50.5c-1.5 0-2.5 1.2-2.5 2.7v14.1l-15.9-16c-.8-.8-2.2-1-3.2-.6s-1.9 1.4-1.9 2.5v20.7c0 1.5 1.5 2.7 3 2.7s3-1.2 3-2.7v-14.1l16.1 16c.5.5 1.2.8 1.9.8.3 0 .4-.1.7-.2 1-.4 1.3-1.4 1.3-2.5v-20.6c0-1.5-1-2.8-2.5-2.8zM44.2 62.3c-1.4 0-2.7 1.4-2.7 2.8s1.3 2.8 2.7 2.8l6.6.4-1.5 3.7h-8.5l-4.2-7.9 4.3-8.1h9.1l2.1 4h5.5l-3.6-7.9-.8-1.1h-15.6l-.7 1.2-5.9 10.3-.7 1.3.7 1.3 5.8 10.3.8 1.6h15.1l.7-1.7 4.3-9 1.9-4.3h-4.4l-11 .3zM65 50.5c-1.4 0-3 1.3-3 2.7v6.8h6v-6.7c0-1.5-1.6-2.8-3-2.8zM95.4 50.8c-1-.4-2.4-.2-3.1.6l-16.3 16v-14.1c0-1.5-1-2.7-2.5-2.7s-2.5 1.2-2.5 2.7v20.7c0 1.1.7 2.1 1.7 2.5.3.1.7.2 1 .2.7 0 1.6-.3 2.1-.8l16.2-16v14.1c0 1.5 1 2.7 2.5 2.7s2.5-1.2 2.5-2.7v-20.7c0-1.1-.6-2.1-1.6-2.5zM117.2 63.6l8.4-8.4c1.1-1.1 1.1-2.8 0-3.8-1.1-1.1-2.8-1.1-3.8 0l-8.4 8.4-8.4-8.4c-1.1-1.1-2.8-1.1-3.8 0-1.1 1.1-1.1 2.8 0 3.8l8.4 8.4-8.4 8.4c-1.1 1.1-1.1 2.8 0 3.8.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8l8.4-8.4 8.4 8.4c.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8c1.1-1.1 1.1-2.8 0-3.8l-8.4-8.4zM62 73.9c0 1.4 1.5 2.7 3 2.7 1.4 0 3-1.3 3-2.7v-11.9h-6v11.9z"/>
      </svg>
      <span class="tooltip">Nginx</span>
    `}
  />
);

export default NginxIcon;
